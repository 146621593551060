import { Autocomplete, Button, FormControl, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import SelectFilter from "../events/filters/filter/SelectFilter";
import { focusColor } from "../shared/textFieldStyle";
import { useQuery } from "react-query";
import { getAllEvents } from "../../services/eventsServices";
import SearchFilter from "../events/filters/filter/SearchFilter";
import { MAIL_NOTIFICATION_TYPES, MAILING_STATUS, MAILING_TYPES } from "../../constants/variables";
import { MailSettingsIcon } from "../icons/MailSettingsIcon";
import { EmailOutlined } from "@mui/icons-material";

export const MailingFilters = ({ onSearchChange, filters, updateFilter, setOpenMailDialog }) => {
  const { t } = useTranslation();

  const { type, eventId, status } = filters;

  const { data: events = [] } = useQuery(["allEvents"], () => getAllEvents(), {
    refetchOnWindowFocus: false,
  });

  const typeOptions = MAILING_TYPES.map((type) => ({ id: type.id, name: t(type.name) }));
  const statusOptions = MAILING_STATUS.map((status) => ({ id: status.id, name: t(status.name) }));

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={8}
        container
        spacing={1}
        sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
      >
        <Grid item xs={12} md={3}>
          <SearchFilter onSearchChange={onSearchChange} />
        </Grid>
        <Grid item container xs={12} sm={11} md={9} columnGap={1} rowGap={1}>
          <SelectFilter
            value={type}
            onChange={(value) => updateFilter("type", value)}
            options={typeOptions}
          />
          {/* Products */}
          <FormControl sx={[focusColor, { minWidth: 200 }]}>
            <Autocomplete
              id="auto-complete"
              options={events}
              getOptionLabel={(option) => option.name || ""}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.name} ({option.premise_name})
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label={t("PRODUCTS")} variant="outlined" size="small" />
              )}
              onChange={(_, value) => updateFilter("eventId", value?.id)}
              value={events?.find((event) => event?.id === eventId) || null}
              isOptionEqualToValue={(option, value) => option?.id === value.id}
              size="small"
              sx={{
                borderRadius: 3,
                ".MuiOutlinedInput-root": {
                  borderRadius: 3,
                  fontSize: "12px",
                },
              }}
            />
          </FormControl>
          <SelectFilter
            value={status}
            onChange={(value) => updateFilter("status", value)}
            options={statusOptions}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={4} direction="column" spacing={1}>
        <Grid item>
          <Button
            className="oniria-btn"
            variant="contained"
            size="small"
            sx={{
              fontSize: "12px",
              borderRadius: 3,
              width: "100%",
            }}
            onClick={() => setOpenMailDialog({ open: true, type: MAIL_NOTIFICATION_TYPES.DIRECT })}
            startIcon={<EmailOutlined />}
          >
            {t("SEND_NOTIFICATION")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            className="oniria-btn"
            variant="contained"
            size="small"
            sx={{
              fontSize: "12px",
              borderRadius: 3,
              width: "100%",
            }}
            onClick={() =>
              setOpenMailDialog({ open: true, type: MAIL_NOTIFICATION_TYPES.PROGRAMMED })
            }
            startIcon={<MailSettingsIcon />}
          >
            {t("PROGRAM_NOTIFICATION")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
