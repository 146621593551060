import { SERVICE } from "../constants/api";
import http from "../constants/axios";

export const sendEventMail = async (eventId, body) => {
  try {
    const response = await http.post(`${SERVICE.MAILING}/event/${eventId}`, body);

    return response.data;
  } catch (error) {
    console.error("Error sending event mail:", error);
    throw error;
  }
};

export const programEventMail = async (body) => {
  try {
    const response = await http.post(`${SERVICE.MAILING}/plans`, body);

    return response.data;
  } catch (error) {
    console.error("Error sending event mail:", error);
    throw error;
  }
};

export const getMailings = async (filters, page, perPage) => {
  try {
    const { type, eventId, status, search, order } = filters;
    const params = new URLSearchParams();
    if (type && type !== "all") params.append("type", type);
    if (eventId) params.append("event_id", eventId);
    if (status && status !== "all") params.append("status", status);
    if (search) params.append("search", search);
    if (order.asc && order.asc.length > 0) {
      params.append("asc", order.asc.join(","));
    }
    if (order.desc && order.desc.length > 0) {
      params.append("desc", order.desc.join(","));
    }
    if (page) params.append("page", page);
    if (perPage) params.append("perpage", perPage);

    const response = await http.get(`${SERVICE.MAILING}/plans?${params}`);
    return response.data;
  } catch (error) {
    console.error("Error getting mailings:", error);
    throw error;
  }
};

export const getMailingPlanDetails = async (planId) => {
  try {
    const response = await http.get(`${SERVICE.MAILING}/plans/${planId}`);
    return response.data;
  } catch (error) {
    console.error("Error getting mailing plan details:", error);
    throw error;
  }
};

export const changeActiveMailingPlan = async (planId, isActive) => {
  try {
    const response = await http.put(`${SERVICE.MAILING}/plans/${planId}`, {
      newValue: isActive,
    });
    return response.data;
  } catch (error) {
    console.error("Error changing mailing plan:", error);
    throw error;
  }
};
