import { WarningAmberOutlined } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { sanitizeBoolean } from "../../../constants/utils";
import {
  MAIL_NOTIFICATION_TYPES,
  MAIL_PAYMENT_OPTIONS,
  TRIGGER_TIMING,
  TRIGGER_TIMING_OPTIONS,
  TRIGGER_TYPE,
  TRIGGER_TYPE_OPTIONS,
} from "../../../constants/variables";
import { focusColor } from "../../shared/textFieldStyle";
import TextEditor from "../../textEditor/TextEditor";

export const NotificationForm = ({ mailType, hasDoublePayment }) => {
  const { t } = useTranslation();

  const {
    watch,
    setValue,
    formState: { errors },
    control,
  } = useFormContext();

  const triggerType = watch("trigger_type");
  const triggerTiming = watch("trigger_timing");
  const payment = watch("payment");
  const description = watch("message") || "";

  return (
    <Box>
      {/* Payment */}
      {/* {sanitizeBoolean(hasDoublePayment) && mailType === MAIL_NOTIFICATION_TYPES.DIRECT && (
        <Grid container flexDirection="column" sx={{ mb: 1 }}>
          <Typography variant="body2">{t("CHOOSE_PAYMENT_MAIL")}</Typography>
          {MAIL_PAYMENT_OPTIONS.map((option) => (
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  checked={option.value === payment}
                  onChange={(e) => setValue("payment", e.target.checked ? option.value : "")}
                  name="payment"
                  className="checkbox-oniria"
                />
              }
              label={t(option.labelKey)}
            />
          ))}
        </Grid>
      )} */}
      {/* Subject */}
      <Typography variant="body2">{t("MESSAGE_SUBJECT")}</Typography>
      <Controller
        name="subject"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            placeholder={t("MESSAGE_SUBJECT")}
            variant="outlined"
            sx={[focusColor, { mb: 2 }]}
            error={!!errors.subject}
            helperText={t(errors.subject?.message)}
          />
        )}
      />
      {/* Message */}
      <Typography variant="body2">{t("DESCRIPTION")}</Typography>
      <TextEditor
        data={description}
        setData={(data) => setValue("message", data)}
        id={`description-notification-form`}
      />
      {mailType === MAIL_NOTIFICATION_TYPES.PROGRAMMED && (
        <Grid container spacing={2} marginTop={2}>
          {/* Reason */}
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              {t("FOR_WHICH_REASON")}
            </Typography>
            <Controller
              name="trigger_type"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth sx={focusColor}>
                  <InputLabel>{t("CHOOSE_REASON")}</InputLabel>
                  <Select {...field} label={t("CHOOSE_REASON")} variant="outlined">
                    {TRIGGER_TYPE_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {t(option.labelKey)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          {/* Payments */}
          {/* {sanitizeBoolean(hasDoublePayment) &&
            mailType === MAIL_NOTIFICATION_TYPES.PROGRAMMED &&
            triggerType === TRIGGER_TYPE.REALIZED_PAYMENT && (
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  {t("CHOOSE_PAYMENT")}
                </Typography>
                <Controller
                  name="payment"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth sx={focusColor}>
                      <Select {...field}>
                        {MAIL_PAYMENT_OPTIONS.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {t(option.labelKey)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            )} */}

          {/* When */}
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ mb: 1 }}>
              {t("WHEN_SEND_NOTIFICATION")}
            </Typography>
            <Controller
              name="trigger_timing"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth sx={focusColor}>
                  <InputLabel>{t("CHOOSE_MOMENT")}</InputLabel>
                  <Select {...field} label={t("CHOOSE_MOMENT")} variant="outlined">
                    {TRIGGER_TIMING_OPTIONS.filter((option) =>
                      triggerType === TRIGGER_TYPE.REALIZED_PAYMENT
                        ? option.value !== TRIGGER_TIMING.BEFORE_X_DAYS
                        : true
                    ).map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {t(option.labelKey)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          {/* Days */}
          {triggerTiming !== TRIGGER_TIMING.DIRECT && triggerTiming !== "" && (
            <>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  {t("SELECT_AMOUNT_OF_DAYS")}
                </Typography>
                <Controller
                  name="days"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      placeholder="00"
                      type="number"
                      inputProps={{
                        min: 0,
                      }}
                      variant="outlined"
                      sx={focusColor}
                      error={!!errors.days}
                      helperText={t(errors.days?.message)}
                    />
                  )}
                />
              </Grid>
              {/* Time */}
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" mb={1}>
                  {t("CHOOSE_TIME_OPTIONAL")}
                </Typography>
                <Controller
                  name="hour"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="time"
                      variant="outlined"
                      sx={focusColor}
                      error={!!errors.hour}
                      helperText={t(errors.hour?.message)}
                    />
                  )}
                />
                <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                  <WarningAmberOutlined color="error" />
                  <Typography variant="caption" color="error">
                    {t("CHOOSE_TIME_CAPTION")}
                  </Typography>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Box>
  );
};
