import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PAGINATION_STYLES } from "../../../constants/styles";
import { formatDate } from "../../../constants/utils";
import { ASSISTANT_PAYMENT_STATUS_OPTIONS } from "../../../constants/variables";
import { useQueryListAssistants } from "../../../hooks/queries/events/assistants/useQueryListAssistants";
import useDebounce from "../../../hooks/useDebouncing";
import usePagination from "../../../hooks/usePagination";
import SearchFilter from "../../events/filters/filter/SearchFilter";
import { Loading } from "../../shared/Loading";
import { focusColor } from "../../shared/textFieldStyle";

export const NotificationAssistants = ({ setUsersToSend, selectedEventId, usersToSend }) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    search: "",
    ticket_id: "",
    extra_id: "",
    status: "",
    desc: ["created_at"],
  });
  const [selectingAll, setSelectingAll] = useState(false);

  const initialPerPage = 5;
  const { page, changePage, changePerPage, perPage } = usePagination(initialPerPage);

  const { data, isLoading } = useQueryListAssistants(selectedEventId, "", filters, 1, perPage);

  const {
    data: { data: assistants = [], total: totalAssistants = 0 } = {},
    tickets: ticketsTypes = [],
    extras: extrasTypes = [],
  } = data || {};

  // Check users for notifications
  const handleCheckboxUser = (userId, checked) => {
    if (userId === "all") {
      if (checked) {
        changePage(null, 1);
        changePerPage({ target: { value: totalAssistants } });
        setSelectingAll(true);
      } else {
        setUsersToSend([]);
        setSelectingAll(false);
      }
      return;
    }

    // Individual select
    setUsersToSend((prevSelected) => {
      const newSelectedUsers = checked
        ? [...prevSelected, userId]
        : prevSelected.filter((id) => id !== userId);

      // Delete duplicates
      return Array.from(new Set(newSelectedUsers));
    });
  };

  const handleFilterChange = (key, value) => {
    changePage(null, 1);
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleChangeTypeTicket = (e) => handleFilterChange("ticket_id", e.target.value);
  const handleChangeTypeStatus = (e) => handleFilterChange("status", e.target.value);
  const handleChangeTypeExtra = (e) => handleFilterChange("extra_id", e.target.value);
  const handleSearchChange = (e) => handleFilterChange("search", e.target.value);

  const debounceSearch = useDebounce(handleSearchChange, 500);

  const uniqueAssistants = Array.from(
    new Set(assistants.map((assistant) => assistant.buyer_user_id))
  );

  const totalPages = Math.ceil(totalAssistants / perPage) || 1;

  useEffect(() => {
    if (selectingAll && assistants?.length) {
      setUsersToSend((prevSelected) => {
        const allUsers = assistants.map((assistant) => assistant.buyer_user_id);
        // Delete duplicates
        return Array.from(new Set([...prevSelected, ...allUsers]));
      });
      setSelectingAll(false);
    }
  }, [assistants, selectingAll]);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="body2" sx={{ mb: 1 }}>
        {t("SELECT_USERS")}
      </Typography>
      {/* Filters */}
      <Grid container alignItems="center" spacing={1} mb={1}>
        <Grid item xs={12} sm={3} container flexWrap="nowrap">
          <SearchFilter onSearchChange={debounceSearch} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth sx={focusColor}>
            <InputLabel id="demo-simple-select-label" size="small">
              {t("TICKET_TYPE")}
            </InputLabel>
            <Select
              value={filters.ticket_id}
              onChange={handleChangeTypeTicket}
              label={t("TICKET_TYPE")}
              sx={{ fontSize: "14px", borderRadius: 3, height: "40px" }}
            >
              <MenuItem value="">{t("ALL_TICKETS")}</MenuItem>
              {ticketsTypes?.map((ticket, index) => (
                <MenuItem key={index} value={ticket.ticket_id}>
                  {ticket.ticket_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth sx={focusColor}>
            <InputLabel id="demo-simple-select-label" size="small">
              {t("EXTRA_TYPE")}
            </InputLabel>
            <Select
              value={filters.extra_id}
              onChange={handleChangeTypeExtra}
              label={t("EXTRA_TYPE")}
              sx={{ fontSize: "14px", borderRadius: 3, height: "40px" }}
            >
              <MenuItem value="">{t("ALL_EXTRAS")}</MenuItem>
              {extrasTypes?.map((extra, index) => (
                <MenuItem key={index} value={extra.extra_id}>
                  {extra.extra_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth sx={focusColor}>
            <InputLabel id="demo-simple-select-label" size="small">
              {t("PAYMENT_STATUS")}
            </InputLabel>
            <Select
              value={filters.status}
              onChange={handleChangeTypeStatus}
              label={t("PAYMENT_STATUS")}
              sx={{ fontSize: "14px", borderRadius: 3, height: "40px" }}
            >
              {ASSISTANT_PAYMENT_STATUS_OPTIONS?.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {t(option.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {/* Table */}
      {isLoading ? (
        <Loading />
      ) : assistants && assistants.length > 0 ? (
        <>
          <Grid container justifyContent="space-between" alignItems="center" mb={2}>
            <Grid item xs={4}>
              <Typography>
                {t("TOTAL_ASSISTANTS")}: {totalAssistants}
              </Typography>
            </Grid>
            <Grid item container xs={8} justifyContent="flex-end">
              <Pagination
                count={totalPages}
                page={page}
                onChange={changePage}
                sx={PAGINATION_STYLES}
              />
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
                  <TableCell sx={{ padding: 0 }}>
                    <Checkbox
                      checked={usersToSend?.length === uniqueAssistants.length}
                      onChange={(e) => handleCheckboxUser(e.target.value, e.target.checked)}
                      value="all"
                      className="checkbox-oniria"
                    />
                  </TableCell>
                  <TableCell sx={{ padding: 0 }}>{t("NAME")}</TableCell>
                  <TableCell sx={{ padding: 0 }}>{t("SURNAME")}</TableCell>
                  <TableCell sx={{ padding: 0 }}>{t("TICKET")}</TableCell>
                  <TableCell sx={{ padding: 0 }}>{t("EXTRA")}</TableCell>
                  <TableCell sx={{ padding: 0 }}>{t("CREATED_AT")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assistants.map((assistant, index) => (
                  <TableRow key={`${assistant.buyer_user_id}-${index}`}>
                    <TableCell sx={{ padding: 0 }}>
                      <Checkbox
                        checked={usersToSend.includes(assistant.buyer_user_id)}
                        onChange={(e) => handleCheckboxUser(e.target.value, e.target.checked)}
                        value={assistant.buyer_user_id}
                        className="checkbox-oniria"
                      />
                    </TableCell>
                    <TableCell sx={{ padding: 0 }}>{assistant.buyer_name}</TableCell>
                    <TableCell sx={{ padding: 0 }}>{assistant.buyer_surname}</TableCell>
                    <TableCell sx={{ padding: 0 }}>{assistant.ticket_name}</TableCell>
                    <TableCell sx={{ padding: 0 }}>
                      {assistant.extras && assistant.extras.length > 0
                        ? assistant.extras.map((extra) => <p>{extra.name}</p>)
                        : ""}
                    </TableCell>
                    <TableCell sx={{ padding: 0 }}>{formatDate(assistant.created_at)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justifyContent="center" mt={3} mb={4}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={changePage}
              sx={PAGINATION_STYLES}
            />
          </Grid>
        </>
      ) : (
        <Typography variant="body2" sx={{ mb: 1 }}>
          {t("NO_ASSISTANTS")}
        </Typography>
      )}
    </Box>
  );
};
