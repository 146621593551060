import { Autocomplete, FormControl, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { focusColor } from "../shared/textFieldStyle";
import { formatDate } from "../../constants/utils";

export const AutoCompleteEventSelect = ({
  events,
  selectedEventId,
  onEventSelect,
  label = "EVENTS",
}) => {
  const { t } = useTranslation();

  return (
    <FormControl sx={[focusColor, { width: "100%" }]}>
      <Autocomplete
        options={events}
        getOptionLabel={(option) => option.name || ""}
        onChange={(_, value) => onEventSelect(value?.id)}
        value={events?.find((event) => event?.id === selectedEventId) || null}
        renderInput={(params) => (
          <TextField {...params} label={t(label)} variant="outlined" size="small" />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name} ({option.premise_name}) -{" "}
            {option?.start_date ? formatDate(option.start_date) : t("MULTI_DATES")}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option?.id === value.id}
        size="small"
      />
    </FormControl>
  );
};
