import { Visibility } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getOrderAmount,
  getOrderCommission,
  getOrderPremiseCommission,
  getOrderTotalAmount,
  isOrderStatus,
} from "../../constants/orders";
import {
  EURO,
  formatCurrency,
  formatDate,
  formatHours,
  getPremiseName,
} from "../../constants/utils";
import { ORDER_STATUS_TYPES } from "../../constants/variables";
import InfoToolTip from "../shared/InfoToolTip";
import { ROUTES } from "./../../constants/routes";

const OrdersTable = ({
  data,
  handleOpenDetail,
  premises,
  handleSelectOrders,
  selectedOrdersIds,
  fromCards,
  canSeeTotalPrice,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
      <Table sx={{ whiteSpace: "nowrap" }}>
        <TableHead>
          <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
            <TableCell>
              <InfoToolTip text={t("ORDER_SELECT_DOWNLOAD")} />
            </TableCell>
            <TableCell>{t("SKU")}</TableCell>
            <TableCell>{fromCards ? t("CARD_NAME") : t("EVENT_NAME")}</TableCell>
            <TableCell>{t("PREMISE")}</TableCell>
            <TableCell>{t("AMOUNT")}</TableCell>
            <TableCell sx={{ maxWidth: "20px", whiteSpace: "wrap" }}>
              {t("COMMISION_SERVICE")} (oniria)
            </TableCell>
            <TableCell sx={{ maxWidth: "20px", whiteSpace: "wrap" }}>
              {t("PREMISE_COMMISSIONS")}
            </TableCell>
            {canSeeTotalPrice && <TableCell>{t("TOTAL_AMOUNT")}</TableCell>}
            <TableCell>{t("DATE")}</TableCell>
            <TableCell>{t("EMAIL")}</TableCell>
            <TableCell>{t("PAYMENT_TYPE")}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedOrdersIds.includes(row.id)}
                      onChange={handleSelectOrders}
                      value={row.id}
                      className="checkbox-oniria"
                    />
                  }
                />
              </TableCell>
              <TableCell>
                {row.sku} / {row.transaction_id} / {row.payment_type}
              </TableCell>
              <TableCell>
                <Typography
                  className="oniria-colorText"
                  variant="subtitle2"
                  sx={{ fontWeight: "bolder", cursor: "pointer" }}
                  onClick={() => navigate(`/event/${row.event_id}`)}
                >
                  {row.event_name || row.card_name}
                </Typography>
              </TableCell>
              <TableCell>
                <p>{getPremiseName(row.event_premise_id || row.card_premise_id, premises)}</p>
                <p>{row.bought_in_domain}</p>
              </TableCell>
              <TableCell>{EURO.format(getOrderAmount(row))}</TableCell>
              <TableCell>{EURO.format(getOrderCommission(row))}</TableCell>
              <TableCell>{EURO.format(getOrderPremiseCommission(row))}</TableCell>
              {canSeeTotalPrice && <TableCell>{EURO.format(getOrderTotalAmount(row))}</TableCell>}
              <TableCell>
                {row.created_at
                  ? `${formatDate(row.created_at)} ${formatHours(row.created_at)}`
                  : row.first_payment_payed_at
                  ? `${formatDate(row.first_payment_payed_at)} ${formatHours(
                      row.first_payment_payed_at
                    )}`
                  : ""}
              </TableCell>
              <TableCell>
                <Typography
                  className="oniria-colorText"
                  variant="subtitle2"
                  sx={{ fontWeight: "bolder", cursor: "pointer" }}
                  onClick={() => navigate(`${ROUTES.USERS}/info/${row.user_id}`)}
                >
                  {row.email}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor:
                    isOrderStatus(row) === ORDER_STATUS_TYPES.PAYED
                      ? "var(--green-payed)"
                      : isOrderStatus(row) === ORDER_STATUS_TYPES.CANCEL ||
                        isOrderStatus(row) === ORDER_STATUS_TYPES.FAILED
                      ? "var(--orange-error)"
                      : isOrderStatus(row) === ORDER_STATUS_TYPES.PARCIAL
                      ? "var(--orange-parcial)"
                      : isOrderStatus(row) === ORDER_STATUS_TYPES.REFUNDED ||
                        isOrderStatus(row) === ORDER_STATUS_TYPES.REFUND
                      ? "var(--blue-refund)"
                      : "white",
                }}
              >
                {t(isOrderStatus(row))} / {t(row?.payment_type?.toUpperCase())}{" "}
                {row.refund_amount ? `(${formatCurrency(row.refund_amount)})` : ""}
              </TableCell>
              <TableCell>
                <Tooltip title={t("SEE")}>
                  <IconButton onClick={(e) => handleOpenDetail(e, row)}>
                    <Visibility sx={{ color: "var(--secondary-color)" }} />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrdersTable;
