import { ExpandLess } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatCurrency, formatDate } from "../../constants/utils";
import {
  amount,
  commission,
  premiseCommission,
  refundedOrders,
  total,
} from "../../utils/eventStatisticsCalcs";
import EventStatisticsDetail from "./EventDetail";

const EventsTable = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (rowId, index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [`${rowId}-${index}`]: !prev[`${rowId}-${index}`],
    }));
  };

  return (
    <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
      <Table sx={{ whiteSpace: "nowrap" }}>
        <TableHead>
          <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
            <TableCell>{t("NAME")}</TableCell>
            <TableCell>{t("START_DATE")}</TableCell>
            <TableCell>{t("END_DATE")}</TableCell>
            <TableCell>{t("NET_AMOUNT")}</TableCell>
            <TableCell>{t("PREMISE_COMMISSION")}</TableCell>
            <TableCell>{t("COMMISSION_SERVICE")}</TableCell>
            <TableCell>{t("REFUNDS")}</TableCell>
            <TableCell
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                fontWeight: "medium",
              }}
            >
              {t("TOTAL")}
            </TableCell>
            <TableCell sx={{ paddingX: 0, textAlign: "center" }}>{t("ORDERS")}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <Fragment key={`${row.event_id}-${index}`}>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="subtitle2"
                    sx={{ cursor: "pointer" }}
                    onClick={() => navigate(`/event/${row.event_id}`)}
                  >
                    <strong>{row.event_name} </strong>{" "}
                    {row.ticket_name ? `(${row.ticket_name})` : ""}
                  </Typography>
                </TableCell>
                <TableCell>{formatDate(row.start_date)}</TableCell>
                <TableCell>{formatDate(row.end_date)}</TableCell>
                <TableCell>{formatCurrency(amount(row.event_payment_type_sales))}</TableCell>
                <TableCell>
                  {formatCurrency(premiseCommission(row.event_payment_type_sales))}
                </TableCell>
                <TableCell>{formatCurrency(commission(row.event_payment_type_sales))}</TableCell>
                <TableCell>
                  {formatCurrency(refundedOrders(row.event_payment_type_sales))}
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                    fontWeight: "medium",
                  }}
                >
                  {formatCurrency(total(row.event_payment_type_sales))}
                </TableCell>
                <TableCell sx={{ paddingX: 0, textAlign: "center" }}>
                  {parseInt(row.order_count?.[0]?.total_finished_payments || "0") +
                    parseInt(row.order_count?.[0]?.total_partial_payments || "0")}
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => toggleRow(row.event_id, index)}
                    variant="outlined"
                    size="small"
                    className="border-btn-dark"
                    sx={{ textTransform: "none", padding: 0.3 }}
                  >
                    {!!expandedRows[`${row.event_id}-${index}`] ? t("LESS_INFO") : t("MORE_INFO")}
                    {!!expandedRows[`${row.event_id}-${index}`] ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </Button>
                </TableCell>
              </TableRow>

              {/* MORE INFO */}
              <TableRow>
                <TableCell colSpan={10} sx={{ p: 0 }}>
                  <Collapse
                    in={expandedRows[`${row.event_id}-${index}`]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <EventStatisticsDetail row={row} />
                  </Collapse>
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EventsTable;
