import { Grid, InputLabel, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DEFAULT_EVENT_LANGUAGE } from "../../../../constants/variables";
import InfoToolTip from "../../../shared/InfoToolTip";
import { focusColor } from "../../../shared/textFieldStyle";

const TicketPolicy = ({
  ticket,
  errors,
  index,
  checkTicketField,
  isDisabled,
  handleChangeLanguageParam,
  selectedLanguage,
  defaultLanguage = DEFAULT_EVENT_LANGUAGE,
}) => {
  const { t } = useTranslation();

  const selectedLanguageIndex = ticket?.ticket_language_infos?.findIndex(
    (language) => language.language === selectedLanguage
  );

  return (
    <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
      <InputLabel
        sx={{
          fontSize: "18px",
          color: "black",
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
        }}
      >
        {t("POLICY_TEXT")}
        <InfoToolTip text={t("POLICY_TEXT_INFO")} />
      </InputLabel>
      {selectedLanguage === defaultLanguage ? (
        <TextField
          onChange={(e) =>
            checkTicketField(
              "tickets_policy",
              e,
              /^(?!.*[|\\#·$~%¬^[\]*{}_]).{0,2500}$/s,
              t("MAX_2500"),
              true,
              index
            )
          }
          value={ticket?.tickets_policy}
          margin="normal"
          fullWidth
          id="tickets_policy"
          multiline
          rows={4}
          name="tickets_policy"
          label={t("POLICY")}
          autoComplete="tickets_policy"
          error={errors[index].tickets_policy !== ""}
          helperText={errors[index].tickets_policy}
          disabled={isDisabled}
          sx={focusColor}
        />
      ) : (
        <TextField
          onChange={(e) =>
            handleChangeLanguageParam(e.target.value, "tickets_policy", selectedLanguageIndex)
          }
          value={ticket.ticket_language_infos[selectedLanguageIndex]?.tickets_policy}
          margin="normal"
          fullWidth
          id="tickets_policy"
          multiline
          rows={4}
          name="tickets_policy"
          label={t("POLICY")}
          autoComplete="tickets_policy"
          disabled={isDisabled}
          sx={focusColor}
        />
      )}
    </Grid>
  );
};

export default TicketPolicy;
