import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getMailingPlanDetails } from "../../../services/mailingServices";

export const useQueryMailingPlanDetails = (planId) => {
  return useQuery([RQ_KEY.MAILING_PLAN_DETAILS, planId], () => getMailingPlanDetails(planId), {
    refetchOnWindowFocus: false,
    enabled: Boolean(planId),
  });
};
