import { Close, CurrencyExchange } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import {
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import OrderButtons from "../../shared/OrderButtons";

const AssistantsTable = ({
  currentAssistants = [],
  handleSortAssistants,
  filters,
  assistantArrived,
  handleRowClick,
  isCompletePayment,
  handleClickOpenCheck,
  selectedUsers = [],
  handleCheckboxUser,
}) => {
  const { t } = useTranslation();

  const uniqueAssistants = Array.from(
    new Set(currentAssistants.map((assistant) => assistant.buyer_user_id))
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
            <TableCell>
              <Checkbox
                checked={selectedUsers?.length === uniqueAssistants.length}
                onChange={(e) => handleCheckboxUser(e.target.value, e.target.checked)}
                value="all"
                className="checkbox-oniria"
              />
            </TableCell>
            <TableCell>
              <OrderButtons
                columnName={t("NAME")}
                id="buyer_name"
                order={filters}
                handleClickAsc={handleSortAssistants}
                handleClickDesc={handleSortAssistants}
              />
            </TableCell>
            <TableCell>
              <OrderButtons
                columnName={t("SURNAME")}
                id="buyer_surname"
                order={filters}
                handleClickAsc={handleSortAssistants}
                handleClickDesc={handleSortAssistants}
              />
            </TableCell>
            <TableCell>
              <OrderButtons
                columnName={t("TICKET_TYPE")}
                id="ticket_name"
                order={filters}
                handleClickAsc={handleSortAssistants}
                handleClickDesc={handleSortAssistants}
              />
            </TableCell>
            <TableCell>{t("EXTRAS")}</TableCell>
            <TableCell>
              <OrderButtons
                columnName={t("CREATED_AT")}
                id="created_at"
                order={filters}
                handleClickAsc={handleSortAssistants}
                handleClickDesc={handleSortAssistants}
              />
            </TableCell>
            <TableCell>{t("VALIDATE")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentAssistants.map((assistant, index) => (
            <TableRow
              key={index}
              sx={{
                cursor: "pointer",
                backgroundColor: assistantArrived(assistant) ? "var(--green-success)" : "",
              }}
            >
              <TableCell>
                <Checkbox
                  checked={selectedUsers.includes(assistant.buyer_user_id)}
                  onChange={(e) => handleCheckboxUser(e.target.value, e.target.checked)}
                  value={assistant.buyer_user_id}
                  className="checkbox-oniria"
                />
              </TableCell>
              <TableCell onClick={() => handleRowClick(assistant)}>
                {assistant.client_name ? assistant.client_name : assistant.buyer_name}
              </TableCell>
              <TableCell onClick={() => handleRowClick(assistant)}>
                {assistant.client_surname ? assistant.client_surname : assistant.buyer_surname}
              </TableCell>
              <TableCell onClick={() => handleRowClick(assistant)}>
                {assistant.ticket_name}
              </TableCell>
              <TableCell onClick={() => handleRowClick(assistant)}>
                {assistant.extras !== null &&
                  assistant.extras.map((extra, index) => (
                    <Fragment key={index}>
                      {extra.extra_name}
                      {index < assistant.extras.length - 1 && <br />}
                    </Fragment>
                  ))}
              </TableCell>
              <TableCell onClick={() => handleRowClick(assistant)}>
                {new Date(assistant.created_at).toLocaleDateString()}
              </TableCell>
              <TableCell>
                {assistant.refunded_at ? (
                  <Grid container sx={{ cursor: "default" }}>
                    <CurrencyExchange sx={{ color: "var(--secondary-color)", fontSize: "2rem" }} />
                  </Grid>
                ) : isCompletePayment(assistant) ? (
                  assistantArrived(assistant) ? (
                    <Chip
                      label={t("ASSISTANT_ARRIVED")}
                      variant="outlined"
                      sx={{
                        color: "#fff",
                        borderColor: "#fff",
                        borderWidth: "1.2px",
                        "& .MuiChip-icon": {
                          color: "#fff",
                        },
                        fontWeight: "bold",
                      }}
                      icon={<CheckIcon sx={{ fontSize: "1.5rem" }} />}
                    />
                  ) : (
                    <IconButton
                      disabled={
                        assistant.extras.every((extra) => extra.extra_consumed_at !== null) &&
                        assistant.consumed_at !== null
                      }
                      aria-label="check"
                      className={
                        assistant.extras.every((extra) => extra.extra_consumed_at !== null) &&
                        assistant.consumed_at !== null
                          ? "oniria-btn-check-validated"
                          : (assistant.extras.some((extra) => extra.extra_consumed_at !== null) &&
                              assistant.extras.length > 0) ||
                            assistant.consumed_at !== null
                          ? "oniria-btn-icon-gold"
                          : "oniria-btn-check"
                      }
                      onClick={() => handleClickOpenCheck(assistant)}
                    >
                      <CheckIcon />
                    </IconButton>
                  )
                ) : (
                  <Chip
                    label={t("PENDING_PAYMENT")}
                    color="error"
                    icon={<Close sx={{ fontSize: "1.5rem" }} />}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AssistantsTable;
