import { formatCurrency } from "../constants/utils";

export const calculateSum = (sales, key) => {
  return sales.reduce((total, payment) => {
    return total + parseFloat(payment[key] || 0) + parseFloat(payment[`first_payment_${key}`] || 0);
  }, 0);
};

export const amount = (sales) => calculateSum(sales, "total_sales");
export const commission = (sales) => calculateSum(sales, "net_income");
export const premiseCommission = (sales) => calculateSum(sales, "premise_commissions");

export const refundedOrders = (sales) => {
  return sales.reduce((total, payment) => total + parseFloat(payment.total_refunds || "0"), 0);
};

export const total = (sales) => {
  return amount(sales) + commission(sales) + premiseCommission(sales) + refundedOrders(sales);
};

const sumPaymentFields = (payments, fields) => {
  const total = payments.reduce((sum, payment) => {
    return (
      sum +
      fields.reduce((fieldSum, field) => {
        return fieldSum + parseFloat(payment[field] || "0");
      }, 0)
    );
  }, 0);
  return formatCurrency(total);
};

export const calculateTotalSales = (payments) =>
  sumPaymentFields(payments, ["total_sales", "first_payment_total_sales"]);

export const calculatePremiseCommissions = (payments) =>
  sumPaymentFields(payments, ["premise_commissions", "first_payment_premise_commissions"]);

export const calculateNetIncome = (payments) =>
  sumPaymentFields(payments, ["net_income", "first_payment_net_income"]);

export const calculateDomainTotal = (payments) => {
  const total = payments.reduce(
    (sum, payment) => sum + parseFloat(getDomainTotalAmount(payment)),
    0
  );
  return formatCurrency(total);
};

export const getDomainTotalAmount = (payment) => {
  const {
    first_payment_net_income,
    first_payment_premise_commissions,
    first_payment_total_sales,
    net_income,
    premise_commissions,
    total_sales,
    total_refunds,
  } = payment;

  const safeValue = (value) => value ?? 0;

  const total =
    parseFloat(safeValue(first_payment_net_income)) +
    parseFloat(safeValue(first_payment_premise_commissions)) +
    parseFloat(safeValue(first_payment_total_sales)) +
    parseFloat(safeValue(net_income)) +
    parseFloat(safeValue(premise_commissions)) +
    parseFloat(safeValue(total_sales)) +
    parseFloat(safeValue(total_refunds));

  return total;
};
