import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";
import { getOrderTotalAmount } from "../constants/orders";
import { formatCurrency, formatCurrencyForCSV, formatDate } from "../constants/utils";
import { ORDER_STATUS_OPTIONS } from "../constants/variables";
import useQueryOrdersEvent from "./queries/events/useQueryOrdersEvent";

const useDownloadOrders = (
  eventId,
  selectedDomain,
  search,
  order,
  selectedTicket,
  selectedStatus,
  enabledDownload
) => {
  const { t } = useTranslation();

  const { data: orders = [], isSuccess } = useQueryOrdersEvent(
    eventId,
    selectedDomain,
    "",
    "",
    search,
    order,
    selectedTicket,
    selectedStatus,
    enabledDownload
  );

  //CSV download
  const tableHeadersCsv = [
    t("NAME"),
    t("SURNAME"),
    t("TRANSACTION_ID"),
    t("DOMAIN"),
    t("TICKETS"),
    t("PAYMENT_DATE"),
    t("PAYMENT_TYPE"),
    t("REFUND_STATUS"),
    t("REFUND_AMOUNT"),
    t("AMOUNT"),
    t("COMMISSION"),
    t("PREMISE_COMMISSION"),
    t("FIRST_PAYMENT_TYPE"),
    t("FIRST_PAYMENT_TRANSACTION_ID"),
    t("FIRST_PAYMENT_AMOUNT"),
    t("FIRST_PAYMENT_COMISSION"),
    t("FIRST_PAYMENT_SEDE_COMISION"),
    t("TOTAL"),
  ];

  const uniqOrders = orders?.reduce(
    (acc, order) => {
      if (!acc.seen[order.order_id]) {
        acc.seen[order.order_id] = true;
        acc.result.push(order);
      }
      return acc;
    },
    { seen: {}, result: [] }
  ).result;

  const getTotalAmount = (item) => {
    let total = 0;
    if (!!item.payed_at) {
      total +=
        parseFloat(item.amount || "0") +
        parseFloat(item.commission || "0") +
        parseFloat(item.premise_commission || "0");
    }
    if (!!item.first_payment_payed_at) {
      total +=
        parseFloat(item.first_payment_amount || "0") +
        parseFloat(item.first_payment_commission || "0") +
        parseFloat(item.first_payment_premise_commission || "0");
    }
    total += parseFloat(item.refund_amount || "0");
    return total;
  };

  // Transformación de los datos para la tabla principal
  const tableDataCsv = uniqOrders?.map((item) => {
    return [
      item.buyer_first_name ?? item.tickets[0]?.client_name ?? "",
      item.buyer_last_name ?? item.tickets[0]?.client_surname ?? "",
      item.transaction_id,
      item.bought_in_domain ?? "",
      item.tickets?.map((ticket) => ticket.ticket_name + "\n")?.join("") ?? "",
      item.payed_at ? formatDate(item.payed_at) : t("PENDING_PAYMENT"),
      item.payment_type ? t(item.payment_type.toUpperCase()) : "",
      item.refund_status ? (item.refund_amount == "0.00" ? t("CANCELED") : t("REFUNDED")) : "",
      item.refund_status ? formatCurrencyForCSV(parseFloat(item.refund_amount || "0")) : 0,
      formatCurrencyForCSV(parseFloat(item.amount || "0")),
      formatCurrencyForCSV(parseFloat(item.commission || "0")),
      formatCurrencyForCSV(parseFloat(item.premise_commission || 0)),
      // Mostrar detalles del primer pago si es diferente al método principal
      item.first_payment_type ? t(item.first_payment_type?.toUpperCase() ?? "") : "",
      item.first_payment_type ? t(item.first_payment_transaction_id?.toUpperCase() ?? "") : "",
      !item.refund_status && item.first_payment_type
        ? formatCurrencyForCSV(parseFloat(item.first_payment_amount) || 0)
        : "",
      item.first_payment_type
        ? formatCurrencyForCSV(parseFloat(item.first_payment_commission) || 0)
        : "",
      item.first_payment_type
        ? formatCurrencyForCSV(parseFloat(item.first_payment_premise_commission) || 0)
        : "",

      // Suma total de todos los pagos si no está devuelto sumo todo si esta reembolsado solo sumo las comisiones
      formatCurrencyForCSV(getTotalAmount(item)),
    ];
  });

  // Generar el resumen por tipo de pago
  const summary = uniqOrders.reduce((acc, item) => {
    const type = item.payment_type || "OTHER";
    if (!acc[type]) {
      acc[type] = {
        totalAmount: 0,
        totalCommission: 0,
      };
    }
    acc[type].totalAmount += parseFloat(item.amount || "0");
    acc[type].totalCommission += parseFloat(item.commission || "0");
    return acc;
  }, {});

  // Transformar el resumen a un array para exportarlo como CSV
  const summaryDataCsv = [
    [t("PAYMENT_TYPE"), t("TOTAL_AMOUNT"), t("TOTAL_COMMISSION")],
    ...Object.entries(summary).map(([paymentType, data]) => [
      paymentType,
      formatCurrency(data.totalAmount),
      formatCurrency(data.totalCommission),
    ]),
  ];

  //PDF download
  const headersPdf = [
    t("NAME"),
    t("SURNAME"),
    t("TRANSACTION_ID"),
    t("DOMAIN"),
    t("TICKETS"),
    t("PAYMENT_DATE"),
    t("PAYMENT_TYPE"),
    t("TOTAL"),
  ];

  const tableDataPdf = orders
    ?.filter((item) => item?.type !== ORDER_STATUS_OPTIONS.REFUND)
    ?.map((item) => [
      item.client_name ?? item.buyer_first_name ?? "",
      item.client_surname ?? item.buyer_last_name ?? "",
      item.transaction_id,
      item.bought_in_domain ?? "",
      item.tickets
        ?.map((ticket) => {
          return ticket.ticket_name + "\n";
        })
        ?.join("") ?? "",
      item.payed_at ? formatDate(item.payed_at) : t("PENDING_PAYMENT"),
      item.payment_type ? t(item?.payment_type?.toUpperCase()) : "",
      formatCurrency(getOrderTotalAmount(item) || 0),
    ]);

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [headersPdf],
      body: tableDataPdf,
      styles: { fontSize: 10 },
      theme: "grid",
      margin: { top: 30 },
    });
    doc.save("orders.pdf");
  };

  return {
    generatePDF,
    tableHeadersCsv,
    tableDataCsv,
    isSuccess,
  };
};

export default useDownloadOrders;
