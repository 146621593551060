export const ROUTES = {
  ACCOUNT_SUCCESS: "/account_success",
  ADD_ASSISTANT: "/event/:eventId/add_assistant",
  ADMIN_DETAIL: "/admins/:adminId",
  ADMINS: "/admins",
  APP_STYLES: "/styles/:domainId",
  BILLING: "/billing",
  BLOG: "/blog",
  BLOG_DETAIL: "/blog/:postId",
  CREATE_BLOG_POST: "/create-post",
  EDIT_BLOG_POST: "/edit-post/:id",
  CALENDAR: "/calendar",
  CATEGORIES: "/categories",
  CARDS: "/cards",
  CREATE_ACCOUNT: "/createaccount",
  CREATE_ACTIVITY: "/create_activity",
  CREATE_ADMIN_SIMPLE: "/admins/create_admin_simple",
  CREATE_ADMIN: "/admins/create_admin",
  CREATE_EVENT_WITH_ID: "/create_event/:eventId",
  CREATE_EVENT: "/create_event",
  CREATE_ORGANIZATION: "/create_organization",
  CREATE_PREMISE: "/create_premise",
  CREATE_RRPP: "/rrpps/create_rrpp",
  CREATE_TRIP_WITH_ID: "/create_trip/:eventId",
  CREATE_TRIP: "/create_trip",
  CREATE_USER_SIMPLE: "/users/create_user_simple",
  CREATE_USER: "/users/create_user",
  DOMAIN_ADMINS: "/domains/admins/:domainId",
  DOMAINS: "/domains",
  EDIT_RRPP: "/rrpps/edit_rrpp/:userId",
  ERROR_404: "/404",
  EVENT_DETAIL: "/event/:eventId",
  EVENTS: "/events",
  EVENT: "/event",
  EXTRA_ACTIVITIES: "/extra_activites",
  FORGOT_PASSWORD: "/forgot",
  HOME: "/home",
  HOTELS: "/hotels",
  CREATE_HOTEL: "/hotels/create",
  EDIT_HOTEL: "/hotels/edit/:id",
  EDIT_HOTEL_TRIP: "/hotels/edit-trip/:id",
  VIEW_HOTEL: "/hotels/:id",
  INCLUDES: "/includes",
  LOGIN: "/login",
  ORDERS: "/orders",
  ORGANIZATION_DETAIL: "/organization/:organizationId",
  ORGANIZATIONS: "/organizations",
  PENDING_USERS: "/users/pending_users",
  PREMISE_DETAIL: "/premise/:premiseId",
  PREMISE_MEMBER_CARD: "/premise/:premiseId/member_card",
  PREMISE_PENDING_USERS: "/premise/:premiseId/pending_users",
  PREMISE: "/premise",
  PREMISES: "/premises",
  REFUNDS: "/refunds",
  RESET_PASSWORD: "/reset_password",
  RRPP_DETAIL: "/rrpps/:userId",
  RRPPS: "/rrpps",
  SALES: "/sales",
  SALESSEO: "/sales-seo",
  STATISTICS: "/statistics",
  TPV: "/tpv",
  TRANSPORT: "/transport",
  TRIPS: "/trips",
  USER_DETAIL: "/users/:userId",
  USER_INFO: "/users/info/:userId",
  USERS: "/users",
  VALIDATE_ORGANIZATIONS: "/organizations/validation",
  VALIDATE_PREMISES: "/premises/validation",
  VERIFY_APP: "/verify-app",
  VERIFY_DASHBOARD: "/verify-dashboard",
  VERIFY_UOP: "/verify-uop",
  EXTRAS: "/extras",
  TAGS: "/tags",
  CREATE_TAG: "/tags/create",
  EDIT_TAG: "/tags/edit/:id",
  EVENT_STATISTICS: "/events/statistics",
  EDIT_EXTRA: "/extras/edit/:id",
  CREATE_EXTRA: "/extras/create",
  EXTRA_EVENTS: "/extra/events/:id",
  ADD_EXTRA_EVENT: "event/:eventId/extra/:extraId",
  TOUR_POINTS: "/tour-points",
  CREATE_TOUR_POINT: "/tour-points/create",
  EDIT_TOUR_POINT: "/tour-points/edit/:id",
  TRANSPORTS: "/transports",
  CREATE_TRANSPORT: "/transports/create",
  EDIT_TRANSPORT: "/transports/edit/:id",
  CREATE_TRIP_TRANSPORT: "/transports/create_trip_transport",
  EDIT_TRIP_TRANSPORT: "/transports/edit_trip_transport/:id",
  CREATE_TRIP_HOTEL: "/hotels/create_trip_hotel",
  WIKIBLISS: "/wikibliss",
  TOUR_LEADERS: "/tour-leaders",
  CREATE_TOUR_LEADER: "/tour-leaders/create",
  EDIT_TOUR_LEADER: "/tour-leaders/edit/:id",
  CREATE_TRIP_PLUS: "/trips/create_trip_plus",
  DUPLICATE_TRIP_PLUS: "/trips/create_trip_plus/:id",
  CREATE_CHILD_TRIP: "/trips/create_child_trip/:id",
  DUPLICATE_BLOG: "duplicate-post/:id",
  MAILING: "/notifications",
};
