import { useQuery } from "react-query";
import { RQ_KEY } from "../../../../constants/query";
import { getAllAssistants } from "../../../../services/eventsServices";

export const useQueryListAssistants = (eventId, selectedDomain, filters, page, perPage) =>
  useQuery(
    [RQ_KEY.ASSISTANTS_EVENT_ID, eventId, selectedDomain, filters, page, perPage],
    () => getAllAssistants(eventId, selectedDomain, page, perPage, filters),
    {
      refetchOnWindowFocus: false,
      enabled: !!eventId,
    }
  );
