import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getMailings } from "../../../services/mailingServices";

export const useQueryMailing = (filters, page, perPage) => {
  return useQuery(
    [RQ_KEY.MAILINGS, filters, page, perPage],
    () => getMailings(filters, page, perPage),
    {
      refetchOnWindowFocus: false,
    }
  );
};
