import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DIVIDER_STYLE } from "../../../constants/styles";
import InfoToolTip from "../../shared/InfoToolTip";
import LanguageTabs from "../../shared/languages/LanguageTabs";
import { focusColor } from "../../shared/textFieldStyle";
import TextEditor from "../../textEditor/TextEditor";
import TripDates from "./TripDates";
import TripImages from "./TripImages";
import TripLanguages from "./TripLanguages";
import TripNumbers from "./TripNumbers";
import { VisibleSwitch } from "../../shared/switch/VisibleSwitch";

const PrincipalInformationPlus = () => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const eventLanguages = watch("event_language_infos") || [];
  const defaultLanguage = watch("default_language");

  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const languageIndex = eventLanguages?.findIndex((info) => info.language === selectedLanguage);

  return (
    <>
      {/* VISIBLE TRIP */}
      <Grid item container xs={12} spacing={4}>
        <Grid item xs={10}>
          <Typography variant="h5">{t("PRINCIPAL_INFORMATION")}</Typography>
          <Typography variant="body1">{t("PRINCIPAL_INFORMATION_DESCRIPTION")}</Typography>
        </Grid>
        <Grid item xs={2}>
          <div>
            {t("VISIBLE_TRIP")}
            <InfoToolTip text={t("VISIBLE_TRIP_INFO")} />
          </div>
          <VisibleSwitch
            checked={watch("is_visible") || false}
            handleChange={(e) => setValue("is_visible", e.target.checked)}
          />
        </Grid>
      </Grid>
      <Divider sx={DIVIDER_STYLE} />

      <TripLanguages />
      <Divider sx={DIVIDER_STYLE} />

      <TripImages />
      <Divider sx={DIVIDER_STYLE} />

      <LanguageTabs
        languagesInfo={eventLanguages}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        defaultLanguage={defaultLanguage}
      />
      <Grid item xs={10}>
        <Typography variant="h6">{t("BASIC_INFO_TRIP")}</Typography>
        <Typography variant="body1">{t("BASIC_INFO_TRIP_DESCRIPTION")}</Typography>
      </Grid>
      {/* NAME TRIP */}
      <Grid item xs={12}>
        <Typography>{t("TRIP_TITLE")}*</Typography>
        {selectedLanguage === defaultLanguage ? (
          <>
            <TextField
              {...register("name")}
              error={!!errors?.name}
              helperText={errors?.name?.message}
              fullWidth
              sx={focusColor}
              placeholder={t("TITLE")}
              required
            />
          </>
        ) : (
          <TextField
            {...register(`event_language_infos.${languageIndex}.name`)}
            error={!!errors?.event_language_infos?.[languageIndex]?.name}
            helperText={errors?.event_language_infos?.[languageIndex]?.name?.message}
            fullWidth
            sx={focusColor}
            placeholder={t("TITLE")}
            required
          />
        )}
      </Grid>
      {/* SUBTITLE TRIP */}
      <Grid item xs={12}>
        <Typography>{t("SUBTITLE")}</Typography>
        {selectedLanguage === defaultLanguage ? (
          <>
            <TextField
              {...register("subtitle")}
              error={!!errors?.subtitle}
              helperText={errors?.subtitle?.message}
              fullWidth
              sx={focusColor}
              placeholder={t("SUBTITLE")}
            />
          </>
        ) : (
          <TextField
            {...register(`event_language_infos.${languageIndex}.subtitle`)}
            error={!!errors?.event_language_infos?.[languageIndex]?.subtitle}
            helperText={errors?.event_language_infos?.[languageIndex]?.subtitle?.message}
            fullWidth
            sx={focusColor}
            placeholder={t("SUBTITLE")}
          />
        )}
      </Grid>
      {/* SHORT DESCRIPRION */}
      <Grid item xs={12}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>{t("SHORT_DESCRIPTION")}</Typography>
          <InfoToolTip text={t("EVENT_CARD_SHORT_DESCRIPTION")} />
        </Box>
        {selectedLanguage === defaultLanguage ? (
          <>
            <TextField
              {...register("short_description")}
              error={!!errors?.short_description}
              helperText={errors?.short_description?.message}
              fullWidth
              sx={focusColor}
              placeholder={t("SHORT_DESCRIPTION")}
            />
          </>
        ) : (
          <TextField
            {...register(`event_language_infos.${languageIndex}.short_description`)}
            error={!!errors?.event_language_infos?.[languageIndex]?.short_description}
            helperText={errors?.event_language_infos?.[languageIndex]?.short_description?.message}
            fullWidth
            sx={focusColor}
            placeholder={t("SHORT_DESCRIPTION")}
          />
        )}
      </Grid>
      {/* DESCRIPTION */}
      <Grid item xs={12}>
        <Typography>{t("DESCRIPTION")}*</Typography>
        {selectedLanguage === defaultLanguage ? (
          <>
            <TextEditor
              data={watch("description")}
              id={`trip_plus_description`}
              setData={(data) => setValue("description", data)}
            />
          </>
        ) : (
          <TextEditor
            data={watch(`event_language_infos.${languageIndex}.description`)}
            id={`trip_plus_description-${languageIndex}`}
            setData={(data) => setValue(`event_language_infos.${languageIndex}.description`, data)}
          />
        )}
      </Grid>

      <Divider sx={DIVIDER_STYLE} />
      <TripDates />

      <Divider sx={DIVIDER_STYLE} />
      <TripNumbers />
    </>
  );
};

export default PrincipalInformationPlus;
