import { ChevronLeft } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQueryMailingPlanDetails } from "../../hooks/queries/mailing/useQueryMailingPlanDetails";
import { Loading } from "../shared/Loading";
import { checkStringIsHtml } from "../../constants/variables";
import { formatDate } from "../../constants/utils";

export const MailingPlanDetails = ({ planId, handleClose }) => {
  const { t } = useTranslation();

  const { data: planData = {}, isLoading } = useQueryMailingPlanDetails(planId);

  const { subject, message, mailing_plans_sent = [] } = planData;

  const isHtmlMessage = checkStringIsHtml(message);

  const handleBack = () => {
    handleClose();
  };

  console.log(planData);

  return (
    <Box>
      <Tooltip title={t("GO_BACK")}>
        <IconButton onClick={handleBack}>
          <ChevronLeft />
        </IconButton>
      </Tooltip>
      {isLoading ? (
        <Loading />
      ) : planData ? (
        <Box mt={4} mb={2}>
          {planData.date_to_send ? (
            <Typography variant="body2" sx={{ color: "var(--oniria-red)", mb: 1 }}>
              {t("PROGRAMMED_FOR")} {formatDate(planData.date_to_send)} {planData?.hour}
            </Typography>
          ) : planData.dates_to_send && planData.dates_to_send.length > 0 ? (
            <div style={{ color: "var(--oniria-red)" }}>
              {t("PROGRAMMED_FOR")}{" "}
              {planData.dates_to_send.map((date) => formatDate(date.date_to_send)).join(", ")}
            </div>
          ) : null}
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {t("NOTIFICATION")}
          </Typography>

          <Box mt={2}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {t("NOTIFICATION_SUBJECT")}
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, ml: 1, fontSize: "1.1rem" }}>
              {subject}
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {t("DESCRIPTION")}
            </Typography>
            {isHtmlMessage ? (
              <Typography
                variant="body1"
                sx={{ mt: 1, ml: 1, fontSize: "1.1rem" }}
                dangerouslySetInnerHTML={{ __html: message }}
              />
            ) : (
              <Typography variant="body1" sx={{ mt: 1, ml: 1, fontSize: "1.1rem" }}>
                {message}
              </Typography>
            )}
          </Box>

          <Box mt={3} mb={3}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {t("USERS_RECEIVED_NOTIFICATION")}
            </Typography>
            {mailing_plans_sent && mailing_plans_sent.length > 0 ? (
              <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
                <Table sx={{ whiteSpace: "nowrap" }}>
                  <TableHead>
                    <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
                      <TableCell>{t("NAME")}</TableCell>
                      <TableCell>{t("SURNAME")}</TableCell>
                      <TableCell>{t("EMAIL")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mailing_plans_sent.map((row) =>
                      row.users && row.users.length > 0
                        ? row.users.map((user) => (
                            <TableRow key={user.id}>
                              <TableCell>{user.name}</TableCell>
                              <TableCell>{user.surname}</TableCell>
                              <TableCell>{user.email}</TableCell>
                            </TableRow>
                          ))
                        : null
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography variant="body1" sx={{ mt: 1, ml: 1, fontSize: "1.1rem" }}>
                {t("NO_DATA_AVAILABLE")}
              </Typography>
            )}
          </Box>
        </Box>
      ) : (
        <Typography variant="h6">{t("NO_DATA_AVAILABLE")}</Typography>
      )}
    </Box>
  );
};
