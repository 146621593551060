import { InputLabel, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DEFAULT_EVENT_LANGUAGE } from "../../../../constants/variables";
import { focusColor } from "../../../shared/textFieldStyle";
import TextEditor from "../../../textEditor/TextEditor";
import { checkRenderEditorJs } from "../../../textEditor/tools";

const TicketDescription = ({
  ticket,
  errors,
  index,
  checkTicketField,
  isDisabled,
  catchButtonClicked,
  handleChangeLanguageParam,
  selectedLanguage,
  defaultLanguage = DEFAULT_EVENT_LANGUAGE,
}) => {
  const { t } = useTranslation();

  const selectedLanguageIndex = ticket?.ticket_language_infos?.findIndex(
    (language) => language.language === selectedLanguage
  );

  const handleChangeDescription = (data) => {
    const e = { target: { value: data } };
    checkTicketField("description", e, /^.[\s\S]{0,5000000}$/, t("MAX_5000"), false, index);
  };

  const handleChangeLanguageDescription = (data) => {
    handleChangeLanguageParam(data, "description", selectedLanguageIndex);
  };

  return (
    <>
      <InputLabel sx={{ fontSize: "14px", color: "black" }}>{t("DESCRIPTION")}</InputLabel>
      {selectedLanguage === defaultLanguage ? (
        checkRenderEditorJs("", ticket.description) ? (
          <>
            <TextEditor
              data={ticket.description}
              setData={handleChangeDescription}
              id={`ticket-description-${index}`}
            />
            {errors[index]?.description !== "" && errors[index]?.description !== "empty" ? (
              <p style={{ color: "red" }}>{errors[index].description}</p>
            ) : (
              ""
            )}
          </>
        ) : (
          <TextField
            onChange={(e) =>
              checkTicketField(
                "description",
                e,
                /^(?!.*[|\\#·$~%¬^[\]*{}_])[\s\S]{0,1000}$/,
                t("MAX_1000"),
                false,
                index
              )
            }
            value={ticket?.description}
            margin="normal"
            required
            fullWidth
            id="description"
            name="description"
            label={t("DESCRIPTION")}
            autoComplete="description"
            error={
              (errors[index]?.description !== "" && errors[index]?.description !== "empty") ||
              (errors[index]?.description === "empty" && catchButtonClicked)
            }
            helperText={errors[index].description !== "empty" ? errors[index].description : ""}
            disabled={isDisabled}
            sx={focusColor}
          />
        )
      ) : checkRenderEditorJs(
          "",
          ticket.ticket_language_infos[selectedLanguageIndex]?.description
        ) ? (
        <TextEditor
          data={ticket.ticket_language_infos[selectedLanguageIndex]?.description}
          setData={handleChangeLanguageDescription}
          id={`ticket-description-${selectedLanguage}-${index}`}
        />
      ) : (
        <TextField
          onChange={(e) =>
            handleChangeLanguageParam(e.target.value, "description", selectedLanguageIndex)
          }
          margin="normal"
          required
          fullWidth
          value={ticket.ticket_language_infos[selectedLanguageIndex]?.description}
          id="description"
          name="description"
          autoComplete="description"
          autoFocus
          label={t("DESCRIPTION")}
          disabled={isDisabled}
          sx={focusColor}
        />
      )}
    </>
  );
};

export default TicketDescription;
