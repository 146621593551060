export const MailSettingsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={23}
    fill="none"
    viewBox="-5 0 30 20"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15.9 12.036a.94.94 0 0 1 1.75 0l1.02 2.594h2.721a.972.972 0 0 1 .892.62.937.937 0 0 1-.255 1.036l-2.403 2.148 1.018 2.038a.923.923 0 0 1-.19 1.098.97.97 0 0 1-1.115.16l-2.563-1.45-2.562 1.402a.971.971 0 0 1-1.115-.16.923.923 0 0 1-.19-1.098l1.018-2.037-2.403-2.165a.939.939 0 0 1-.255-1.035.97.97 0 0 1 .891-.557h2.722l1.019-2.595ZM7.225 15.48h-3.98a1.592 1.592 0 0 1-1.592-1.592V2.745a1.592 1.592 0 0 1 1.592-1.592h15.918a1.592 1.592 0 0 1 1.592 1.592v6.367"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m1.653 3.16 9.551 6.748 9.55-6.749"
    />
  </svg>
);
