import { zodResolver } from "@hookform/resolvers/zod";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { sanitizeInt } from "../../../constants/utils";
import { MAIL_NOTIFICATION_TYPES, TRIGGER_TIMING } from "../../../constants/variables";
import { useQueryAssignDomains } from "../../../hooks/queries/useQueryAssignDomains";
import { sendEventMailSchema } from "../../../schemas/mailing";
import { getAllEvents, getEventById } from "../../../services/eventsServices";
import { programEventMail, sendEventMail } from "../../../services/mailingServices";
import { AutoCompleteEventSelect } from "../../events/AutoCompleteEventSelect";
import { toastMessageError, toastMessageSuccess } from "../../shared/toastMessage";
import { convertEditorJsDataToHtml } from "../../textEditor/tools";
import { NotificationAssistants } from "./NotificationAssistants";
import { NotificationForm } from "./NotificationForm";

export const EventNotificationDialog = ({
  open,
  onClose,
  eventId,
  filters,
  selectedUsers = [],
  mailType,
}) => {
  const { t } = useTranslation();
  const { data: domainCategories } = useQueryAssignDomains();
  const [selectedEventId, setSelectedEventId] = useState(eventId);
  const [usersToSend, setUsersToSend] = useState(selectedUsers);
  const [stage, setStage] = useState(eventId ? "form" : "select");

  const { data: events = [] } = useQuery(["allEvents"], () => getAllEvents(), {
    refetchOnWindowFocus: false,
    enabled: !selectedEventId && open,
  });

  const { data = {} } = useQuery(["event", selectedEventId], () => getEventById(selectedEventId), {
    refetchOnWindowFocus: false,
    enabled: !!selectedEventId && open,
  });

  const { event_categories: eventCategories = [], multiple_payments: hasDoublePayment } = data;

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const formMethods = useForm({
    resolver: zodResolver(sendEventMailSchema),
    mode: "onBlur",
    defaultValues: {
      subject: "",
      message: "",
      trigger_timing: "",
      trigger_type: "",
      days: "",
      payment: "",
      hour: "12:00",
    },
  });

  const { handleSubmit, reset } = formMethods;

  const getFirstEventDomain = () =>
    domainCategories?.find((domain) =>
      domain.categories.some(
        (category) =>
          eventCategories?.some(
            (selectedCategory) => selectedCategory.category_id === category.id
          ) ||
          category?.subcategories.some((subcategory) =>
            eventCategories?.some(
              (selectedCategory) => selectedCategory.subcategory_id === subcategory.id
            )
          )
      )
    );

  const handleClose = () => {
    onClose();
    reset();
    setSelectedEventId(null);
    setUsersToSend([]);
    setStage(eventId ? "form" : "select");
  };

  const disabledSendButton = loadingSubmit || !selectedEventId;

  const onSubmit = async (data) => {
    setLoadingSubmit(true);

    try {
      const bodyDirect = {
        domain: getFirstEventDomain()?.name,
        subject: data.subject,
        message: convertEditorJsDataToHtml(data.message),
        ticket_id: filters.ticketId,
        user_ids: selectedUsers,
      };

      const bodyProgrammed = {
        event_id: selectedEventId,
        subject: data.subject,
        message: convertEditorJsDataToHtml(data.message),
        trigger_type: data.trigger_type,
        trigger_timing: data.trigger_timing,
        days: sanitizeInt(data.days) || 0,
        hour: data.trigger_timing === TRIGGER_TIMING.DIRECT ? null : data.hour,
      };

      if (mailType === MAIL_NOTIFICATION_TYPES.PROGRAMMED) {
        await programEventMail(bodyProgrammed);
      } else {
        await sendEventMail(selectedEventId, bodyDirect);
      }

      //Notification
      toastMessageSuccess(t("NOTIFICATION_SENT_SUCCESS"));
      handleClose();
    } catch (error) {
      console.error("Error sending notification:", error);
      toastMessageError(error?.response?.data?.error || t("ERROR"));
    } finally {
      setLoadingSubmit(false);
    }
  };

  const onEventSelect = (eventId) => {
    setSelectedEventId(eventId);
  };

  useEffect(() => {
    if (open) {
      setSelectedEventId(eventId);
    }
  }, [open, eventId]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography fontWeight="bold">
          {mailType === MAIL_NOTIFICATION_TYPES.PROGRAMMED
            ? t("PROGRAMMED_NOTIFICATION")
            : t("SEND_NOTIFICATION")}
        </Typography>
        <Typography variant="body2">{t("SEND_NOTIFICATION_DESCRIPTION")}</Typography>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {stage === "select" ? (
          <Box>
            <Typography variant="body2" sx={{ mb: 1 }}>
              {t("SELECT_PRODUCT_FOR_NOTIFICATION")}
            </Typography>
            <AutoCompleteEventSelect
              events={events}
              selectedEventId={selectedEventId}
              onEventSelect={onEventSelect}
              label="PRODUCTS"
            />
            {mailType === MAIL_NOTIFICATION_TYPES.DIRECT && !!selectedEventId && (
              <NotificationAssistants
                usersToSend={usersToSend}
                setUsersToSend={setUsersToSend}
                selectedEventId={selectedEventId}
              />
            )}
            <Box display="flex" justifyContent="center" marginTop={2} gap={2}>
              <Button onClick={handleClose} className="oniria-border-btn">
                {t("CANCEL")}
              </Button>
              <Button
                type="submit"
                className="oniria-btn"
                sx={{ color: "var(--white)" }}
                onClick={() => setStage("form")}
                disabled={
                  usersToSend.length <= 0 && mailType !== MAIL_NOTIFICATION_TYPES.PROGRAMMED
                }
              >
                {t("WRITE_NOTIFICATION")}
              </Button>
            </Box>
          </Box>
        ) : (
          <FormProvider {...formMethods}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
              <NotificationForm mailType={mailType} hasDoublePayment={hasDoublePayment} />
              {/* Buttons */}
              <Box display="flex" justifyContent="center" marginTop={2} gap={2}>
                <Button onClick={handleClose} className="oniria-border-btn">
                  {t("CANCEL")}
                </Button>
                <Button
                  type="submit"
                  className="oniria-btn"
                  sx={{ color: "var(--white)" }}
                  disabled={disabledSendButton}
                >
                  {loadingSubmit
                    ? t("SENDING")
                    : mailType === MAIL_NOTIFICATION_TYPES.PROGRAMMED
                    ? t("PROGRAM")
                    : t("SEND")}
                </Button>
              </Box>
            </Box>
          </FormProvider>
        )}
      </DialogContent>
    </Dialog>
  );
};
