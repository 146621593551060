import { EmailOutlined } from "@mui/icons-material";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ASSISTANT_PAYMENT_STATUS_OPTIONS,
  MAIL_NOTIFICATION_TYPES,
} from "../../../constants/variables";
import useUserRoles from "../../../hooks/useUserRoles";
import { MailSettingsIcon } from "../../icons/MailSettingsIcon";
import InfoToolTip from "../../shared/InfoToolTip";
import { focusColor } from "../../shared/textFieldStyle";
import SearchFilter from "../filters/filter/SearchFilter";
import { DownloadAssistantsButton } from "./DownloadAssistantsButton";

export const AssistantsFilters = ({
  filters,
  ticketsTypes,
  setOpenMailDialog,
  debounceSearch,
  eventId,
  selectedDomain,
  selectedUsers = [],
  setFilters,
  extrasTypes,
  changePage,
}) => {
  const { t } = useTranslation();
  const { isSuperAdmin, isAdmin, isManager } = useUserRoles();

  const handleFilterChange = (key, value) => {
    changePage(null, 1);
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleChangeTypeTicket = (e) => handleFilterChange("ticket_id", e.target.value);
  const handleChangeTypeStatus = (e) => handleFilterChange("status", e.target.value);
  const handleChangeTypeExtra = (e) => handleFilterChange("extra_id", e.target.value);

  // To send a notification select users
  const disabledSendButton = selectedUsers.length <= 0;

  return (
    <Grid
      container
      sx={{
        marginY: 2,
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
      spacing={2}
    >
      {/* Filters */}
      <Grid item xs={12} md={7} container alignItems="center" spacing={1}>
        <Grid item xs={12} sm={3} container flexWrap="nowrap">
          <SearchFilter onSearchChange={debounceSearch} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth sx={focusColor}>
            <InputLabel id="demo-simple-select-label" size="small">
              {t("TICKET_TYPE")}
            </InputLabel>
            <Select
              value={filters.ticket_id}
              onChange={handleChangeTypeTicket}
              label={t("TICKET_TYPE")}
              sx={{ fontSize: "14px", borderRadius: 3, height: "40px" }}
            >
              <MenuItem value="">{t("ALL_TICKETS")}</MenuItem>
              {ticketsTypes?.map((ticket, index) => (
                <MenuItem key={index} value={ticket.ticket_id}>
                  {ticket.ticket_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth sx={focusColor}>
            <InputLabel id="demo-simple-select-label" size="small">
              {t("EXTRA_TYPE")}
            </InputLabel>
            <Select
              value={filters.extra_id}
              onChange={handleChangeTypeExtra}
              label={t("EXTRA_TYPE")}
              sx={{ fontSize: "14px", borderRadius: 3, height: "40px" }}
            >
              <MenuItem value="">{t("ALL_EXTRAS")}</MenuItem>
              {extrasTypes?.map((extra, index) => (
                <MenuItem key={index} value={extra.extra_id}>
                  {extra.extra_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth sx={focusColor}>
            <InputLabel id="demo-simple-select-label" size="small">
              {t("PAYMENT_STATUS")}
            </InputLabel>
            <Select
              value={filters.status}
              onChange={handleChangeTypeStatus}
              label={t("PAYMENT_STATUS")}
              sx={{ fontSize: "14px", borderRadius: 3, height: "40px" }}
            >
              {ASSISTANT_PAYMENT_STATUS_OPTIONS?.map((option, index) => (
                <MenuItem key={index} value={option.id}>
                  {t(option.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {/* Action Buttons */}
      <Grid item xs={12} md={5}>
        <Grid container spacing={1} sx={{ flexWrap: { xs: "wrap", sm: "nowrap" }, paddingTop: 4 }}>
          {(isSuperAdmin || isManager || isAdmin) && (
            <Grid item container xs={9} direction="column" spacing={1}>
              <Grid item>
                <InfoToolTip text={disabledSendButton ? t("CHECK_ASSISTANTS_INFO") : ""} arrow>
                  <span>
                    <Button
                      className="oniria-btn"
                      variant="contained"
                      size="small"
                      sx={{
                        fontSize: "12px",
                        borderRadius: 3,
                        width: "100%",
                      }}
                      onClick={() =>
                        setOpenMailDialog({ open: true, type: MAIL_NOTIFICATION_TYPES.DIRECT })
                      }
                      startIcon={<EmailOutlined />}
                      disabled={disabledSendButton}
                    >
                      {t("SEND_NOTIFICATION")}
                    </Button>
                  </span>
                </InfoToolTip>
              </Grid>
              <Grid item>
                <Button
                  className="oniria-btn"
                  variant="contained"
                  size="small"
                  sx={{
                    fontSize: "12px",
                    borderRadius: 3,
                    width: "100%",
                  }}
                  onClick={() =>
                    setOpenMailDialog({ open: true, type: MAIL_NOTIFICATION_TYPES.PROGRAMMED })
                  }
                  startIcon={<MailSettingsIcon />}
                >
                  {t("PROGRAM_NOTIFICATION")}
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} sm={7} container>
            <DownloadAssistantsButton
              eventId={eventId}
              selectedDomain={selectedDomain}
              filters={filters}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
