import CheckList from "@editorjs/checklist";
import Code from "@editorjs/code";
import Delimiter from "@editorjs/delimiter";
import Embed from "@editorjs/embed";
import Header from "@editorjs/header";
import Image from "@editorjs/image";
import InlineCode from "@editorjs/inline-code";
import List from "@editorjs/list";
import Marker from "@editorjs/marker";
import Quote from "@editorjs/quote";
import Raw from "@editorjs/raw";
import SimpleImage from "@editorjs/simple-image";
import Table from "@editorjs/table";
import Underline from "@editorjs/underline";
import Warning from "@editorjs/warning";
import { uploadImage } from "../../services/utilsServices";

export const EDITOR_JS_TOOLS = {
  embed: Embed,
  table: Table,
  list: List,
  //warning: Warning,
  //code: Code,
  //raw: Raw,
  header: Header,
  quote: Quote,
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  simpleImage: SimpleImage,
  underline: Underline,
  image: {
    class: Image,
    config: {
      uploader: {
        uploadByFile(file) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async function (event) {
              const base64 = event.target.result;
              const base64WithoutType = base64?.split(";")[1];
              const mimeType = base64?.split(";")[0]?.split(":")[1];
              const response = await uploadImage({ image: base64WithoutType, mime: mimeType });
              resolve({
                success: 1,
                file: {
                  url: response.imageURL,
                },
              });
            };
            reader.onerror = function (error) {
              reject(error);
            };
            reader.readAsDataURL(file);
          });
        },
      },
    },
  },
};

//Check if render EditorJs or not
const parseJson = (str) => {
  try {
    return JSON.parse(str);
  } catch {
    return null;
  }
};

export const checkRenderEditorJs = (path, data) => {
  if (path.includes("create")) return true;
  if (typeof data === "string" && data === "") return true;
  if (!data) return false;

  const jsonData = typeof data === "string" ? parseJson(data) : data;

  return jsonData && jsonData.blocks && Array.isArray(jsonData.blocks);
};

// Editor JS to HTML converter
export const convertEditorJsDataToHtml = (data) => {
  const { blocks } = data;

  if (!Array.isArray(blocks)) {
    console.error("Invalid blocks data. Expected an array.");
    return "";
  }

  // Scape dynamically created HTML
  const escapeHtml = (text) => {
    if (!text) return "";
    const map = {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&#039;",
    };
    return text.replace(/[&<>"']/g, (m) => map[m]);
  };

  // Process each block
  const renderBlock = (block) => {
    if (!block.data) return "";

    switch (block.type) {
      case "header":
        return `<h${block.data.level}>${escapeHtml(block.data.text)}</h${block.data.level}>`;
      case "embed":
        return `<div><iframe width="560" height="315" src="${escapeHtml(
          block.data.embed
        )}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe></div>`;
      case "paragraph":
        return `<p>${escapeHtml(block.data.text)}</p>`;
      case "delimiter":
        return "<hr />";
      case "image":
        return `<img class="img-fluid" style="max-width: 100%; height: auto;" src="${escapeHtml(
          block.data.file.url
        )}" alt="${escapeHtml(block.data.caption || "")}" /><br /><em>${escapeHtml(
          block.data.caption || ""
        )}</em>`;
      case "list": {
        const tag = block.data.style === "ordered" ? "ol" : "ul";
        const items = block.data.items.map((item) => `<li>${escapeHtml(item)}</li>`).join("");
        return `<${tag}>${items}</${tag}>`;
      }
      case "quote":
        return `<blockquote><p>${escapeHtml(block.data.text)}</p><footer>${escapeHtml(
          block.data.caption || ""
        )}</footer></blockquote>`;
      case "code":
        return `<pre><code>${escapeHtml(block.data.code)}</code></pre>`;
      case "table": {
        const rows = block.data.content
          .map((row) => {
            const cells = row.map((cell) => `<td>${escapeHtml(cell)}</td>`).join("");
            return `<tr>${cells}</tr>`;
          })
          .join("");
        return `<table>${rows}</table>`;
      }
      default:
        console.warn("Unknown block type:", block.type);
        return "";
    }
  };

  // Return HTML string
  return blocks.map(renderBlock).join("");
};
