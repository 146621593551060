import { z } from "zod";

export const sendEventMailSchema = z.object({
  subject: z.string().min(1, "REQUIRED_ERROR"),
  message: z.any(),
  trigger_timing: z.string(),
  trigger_type: z.string(),
  days: z.union([z.string(), z.number()]).optional().nullable(),
  payment: z.string().optional(),
  hour: z
    .string()
    .regex(/^([0-1]\d|2[0-3]):([0-5]\d)$/, "INVALID_TIME")
    .optional(),
});
