import { Button, Grid, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { block, blockErrors } from "../../../../classes/tripClass";
import { DEFAULT_EVENT_LANGUAGE } from "../../../../constants/variables";
import { Loading } from "../../../shared/Loading";
import LanguageTabs from "../../languages/LanguageTabs";
import Block from "./Block";

const Blocks = ({
  setFormErrors,
  trip,
  moreInformationErrors,
  setFormParamsValue,
  formData,
  formController,
  buttonClicked,
  editEvent,
  event_language_infos,
  defaultLanguage = DEFAULT_EVENT_LANGUAGE,
}) => {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);
  const [forceRender, setForceRender] = useState(false);

  const forceUpdate = () => {
    setForceRender((prev) => !prev);
    setTimeout(() => {
      setForceRender((prev) => !prev);
    }, 100);
  };

  const setBlocksErrors = (updatedBlocksErrors) => {
    setFormErrors("moreInformation", "blocks", updatedBlocksErrors);
  };

  // Function to add a block to the state
  const addBlock = () => {
    const newBlock = JSON.parse(JSON.stringify(block));
    newBlock.position = trip.blocks.length;
    if (event_language_infos?.length > 0) {
      newBlock.language = selectedLanguage;
    }
    const newBlockErrors = JSON.parse(JSON.stringify(blockErrors));
    const blocksNewState = [...trip.blocks, newBlock];
    setBlocksErrors([...moreInformationErrors.blocks, newBlockErrors]);

    setFormParamsValue("moreInformation", "blocks", blocksNewState);
  };

  // Function to remove a block from the state
  const removeBlock = (index) => {
    const parseIndex = parseInt(index);
    const updatedBlocks = trip.blocks.filter((_, blockIndex) => blockIndex !== parseIndex);
    setFormParamsValue("moreInformation", "blocks", updatedBlocks);
    //
    const updatedBlocksErrors = moreInformationErrors.blocks.filter(
      (_, blockIndex) => blockIndex !== index
    );
    setBlocksErrors(updatedBlocksErrors);
  };

  const handleBlockChange = (index, key, e) => {
    const blocksUpdated = formData.moreInformation.blocks;
    blocksUpdated[index][key] = e.target.value;
    setFormParamsValue("moreInformation", "blocks", blocksUpdated);
  };

  const handleBlockImageChange = (index, key, url) => {
    //
    let images;
    if (Array.isArray(url)) {
      const imageArray = [];
      for (let i = 0; i < url.length; i++) {
        const urlParams = url[i].split(";");
        const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
        imageArray.push(imageObject);
      }
      images = imageArray;
    } else {
      const urlParams = url.split(";");
      const imageObject = { image: urlParams[1], mime: urlParams[0].split(":")[1] };
      images = [imageObject];
    }
    //
    const blocksUpdated = formData.moreInformation.blocks;
    blocksUpdated[index][key] = images;
    setFormParamsValue("moreInformation", "blocks", blocksUpdated);
  };

  //For the drag and drop
  const handleSetBlocksPosition = ({ destination, source }) => {
    if (!destination) return;
    const newBlocks = [...(trip.blocks || [])];
    const [removed] = newBlocks.splice(source.index, 1);
    newBlocks.splice(destination.index, 0, removed);

    const updatedBlocks = newBlocks.map((block, index) => ({
      ...block,
      position: index,
    }));

    setFormParamsValue("moreInformation", "blocks", updatedBlocks);
    forceUpdate();
  };

  useEffect(() => {
    forceUpdate();
  }, [selectedLanguage]);

  useEffect(() => {
    if (defaultLanguage) {
      setSelectedLanguage(defaultLanguage);
    }
  }, [defaultLanguage]);

  return (
    <Grid item xs={11} sx={{ mt: 2, mb: 2 }}>
      <InputLabel sx={{ fontSize: "18px", color: "black", mb: 2 }}>{t("MORE_INFO")}</InputLabel>
      {event_language_infos?.length > 0 && trip?.blocks?.length > 0 ? (
        <LanguageTabs
          event_languages_info={event_language_infos}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          defaultLanguage={defaultLanguage}
        />
      ) : null}
      {forceRender ? (
        <Loading height={"100px"} />
      ) : (
        <DragDropContext onDragEnd={handleSetBlocksPosition}>
          <Droppable droppableId="droppable-list">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  width: "100%",
                }}
              >
                {trip?.blocks
                  ?.filter((block) => {
                    if (event_language_infos.length > 0) {
                      return (
                        block.language === selectedLanguage ||
                        (selectedLanguage === defaultLanguage && !block.language)
                      );
                    }
                    return true;
                  })
                  .map(
                    (block, i) =>
                      block && (
                        <Block
                          key={i}
                          index={block.position}
                          removeBlock={removeBlock}
                          formController={formController}
                          blocksErrors={moreInformationErrors.blocks}
                          setBlocksErrors={setBlocksErrors}
                          handleBlockChange={handleBlockChange}
                          buttonClicked={buttonClicked}
                          handleBlockImageChange={handleBlockImageChange}
                          editEvent={editEvent}
                        />
                      )
                  )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <Button
        fullWidth
        variant="outlined"
        size="large"
        sx={{
          color: "var(--secondary-color)",
          borderColor: "var(--secondary-color)",
          "&:hover": {
            color: "var(--secondary-color)",
            borderColor: "var(--secondary-color)",
            backgroundColor: "white",
          },
          mt: 2,
        }}
        onClick={addBlock}
      >
        {t("ADD_BLOCK")}
      </Button>
    </Grid>
  );
};

export default Blocks;
