import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../constants/utils";
import { PAYMENT_OPTIONS_TYPES } from "../../constants/variables";
import {
  calculateDomainTotal,
  calculateNetIncome,
  calculatePremiseCommissions,
  calculateTotalSales,
} from "../../utils/eventStatisticsCalcs";

const EventStatisticsDetail = ({ row }) => {
  const { t } = useTranslation();

  // Agrupar pagos
  const groupByDomain = (data) => {
    return data.reduce((acc, payment) => {
      if (
        payment.payment_type?.includes("timeout") ||
        payment.payment_type?.includes("failed") ||
        payment.payment_type?.includes("free") ||
        payment.first_payment_type?.includes("failed") ||
        payment.first_payment_type?.includes("timeout")
      ) {
        return acc;
      }
      const domain = payment.bought_in_domain || t("UNKNOWN_DOMAIN");
      if (!acc[domain]) {
        acc[domain] = [];
      }
      acc[domain].push(payment);
      return acc;
    }, {});
  };

  const groupByPaymentType = (payments) => {
    const groupedPayments = payments.reduce((acc, payment) => {
      const addToGroup = (groupKey, paymentInfo) => {
        if (!acc[groupKey]) {
          acc[groupKey] = [];
        }
        acc[groupKey].push(paymentInfo);
      };

      // Find the group by payment type
      const findPaymentGroup = (paymentType) => {
        return (
          PAYMENT_OPTIONS_TYPES.find((type) => paymentType?.toLowerCase().includes(type)) || "other"
        );
      };

      // First payment type
      if (payment?.first_payment_type) {
        const firstPaymentGroup = findPaymentGroup(payment.first_payment_type);
        addToGroup(firstPaymentGroup, {
          type: "first_payment",
          net_income: payment.first_payment_net_income,
          premise_commissions: payment.first_payment_premise_commissions,
          total_sales: payment.first_payment_total_sales,
          payment_type: payment.first_payment_type,
        });
      }

      // Main payment type
      if (payment?.payment_type) {
        const paymentGroup = findPaymentGroup(payment.payment_type);
        addToGroup(paymentGroup, {
          type: "payment",
          net_income: payment.net_income,
          premise_commissions: payment.premise_commissions,
          total_sales: payment.total_sales,
          total_refunds: payment.total_refunds,
          payment_type: payment.payment_type,
        });
      }

      return acc;
    }, {});

    return groupedPayments;
  };

  const groupedPayments = groupByDomain(row.event_payment_type_sales);

  const ticketConsumptions =
    row.ticket_consumitions?.filter((ticket) => !!ticket.ticket_name) || [];
  const extrasConsumptions = row.extra_consumitions?.filter((extra) => !!extra.extra_name) || [];

  return (
    <Box
      sx={{
        margin: 2,
        padding: 3,
        border: "1px solid #ddd",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{ color: "#333", fontWeight: "bold", paddingBottom: 1 }}
      >
        {t("DOMAIN_SALES")}:
      </Typography>
      <p>*Tenga en cuenta que los gastos de las devoluciones NO se devuelven</p>
      {Object.keys(groupedPayments).length > 0 ? (
        Object.keys(groupedPayments).map((domain, index) => (
          <div key={index}>
            <Typography
              sx={{ paddingLeft: "16px", paddingBottom: "0px", paddingTop: "15px", color: "#333" }}
            >
              <strong>{domain}:</strong>
            </Typography>
            <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "20%", textAlign: "center" }}>
                      {t("PAYMENT_TYPE")}
                    </TableCell>
                    <TableCell sx={{ width: "20%", textAlign: "center" }}>
                      {t("TOTAL_SALES")} sin gastos
                    </TableCell>
                    <TableCell sx={{ width: "20%", textAlign: "center" }}>
                      {t("PREMISE_COMMISSIONS")}
                    </TableCell>
                    <TableCell sx={{ width: "20%", textAlign: "center" }}>
                      {t("COMMISSION_SERVICE")}
                    </TableCell>
                    <TableCell sx={{ width: "20%", textAlign: "center" }}>{t("TOTAL")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(groupByPaymentType(groupedPayments[domain])).length > 0
                    ? Object.keys(groupByPaymentType(groupedPayments[domain]))?.map(
                        (paymentType) => {
                          const payments = groupByPaymentType(groupedPayments[domain])[paymentType];

                          return (
                            <TableRow key={paymentType}>
                              <TableCell sx={{ width: "20%", textAlign: "left" }}>
                                {t(paymentType?.toUpperCase())}
                              </TableCell>
                              <TableCell sx={{ width: "20%", textAlign: "center" }}>
                                {calculateTotalSales(payments)}
                              </TableCell>
                              <TableCell sx={{ width: "20%", textAlign: "center" }}>
                                {calculatePremiseCommissions(payments)}
                              </TableCell>
                              <TableCell sx={{ width: "20%", textAlign: "center" }}>
                                {calculateNetIncome(payments)}
                              </TableCell>
                              <TableCell sx={{ width: "20%", textAlign: "center" }}>
                                {calculateDomainTotal(payments)}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )
                    : null}
                  {/* Fila para el total por dominio */}
                  <TableRow>
                    <TableCell sx={{ width: "20%", textAlign: "left" }}>
                      <strong>{t("TOTAL")}</strong>
                    </TableCell>
                    <TableCell sx={{ width: "20%", textAlign: "center" }}>
                      <strong>{calculateTotalSales(groupedPayments[domain])}</strong>
                    </TableCell>
                    <TableCell sx={{ width: "20%", textAlign: "center" }}>
                      <strong>{calculatePremiseCommissions(groupedPayments[domain])}</strong>
                    </TableCell>
                    <TableCell sx={{ width: "20%", textAlign: "center" }}>
                      <strong>{calculateNetIncome(groupedPayments[domain])}</strong>
                    </TableCell>
                    <TableCell sx={{ width: "20%", textAlign: "center" }}>
                      <strong>{calculateDomainTotal(groupedPayments[domain])}</strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))
      ) : (
        <Typography sx={{ paddingLeft: "16px", paddingBottom: "8px", color: "#555" }}>
          {t("NO_SALES")}
        </Typography>
      )}
      {/* TICKETS */}
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: "#333",
          fontWeight: "bold",
          paddingTop: 2,
          paddingBottom: 1,
        }}
      >
        {t("TICKETS_CONSUMPTIONS")}:
      </Typography>
      {ticketConsumptions && ticketConsumptions.length > 0 ? (
        ticketConsumptions.map((ticket, index) => (
          <Typography key={index} sx={{ paddingLeft: "16px", paddingBottom: "8px", color: "#555" }}>
            <strong style={{ color: "#333" }}>{ticket.ticket_name}: </strong>
            {ticket.total_purchased_tickets} {t("PURCHASED")},{ticket.total_consumed_tickets}{" "}
            {t("CONSUMED")}, {t("AMOUNT")}: {formatCurrency(ticket.total_amount)}
          </Typography>
        ))
      ) : (
        <Typography sx={{ paddingLeft: "16px", paddingBottom: "8px", color: "#555" }}>
          {t("NO_SALES")}
        </Typography>
      )}

      {/* EXTRAS */}
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: "#333",
          fontWeight: "bold",
          paddingTop: 2,
          paddingBottom: 1,
        }}
      >
        {t("EXTRA_CONSUMPTIONS")}:
      </Typography>
      {extrasConsumptions && extrasConsumptions.length > 0 ? (
        extrasConsumptions.map((extra, index) => (
          <Typography key={index} sx={{ paddingLeft: "16px", paddingBottom: "8px", color: "#555" }}>
            <strong style={{ color: "#333" }}>{extra.extra_name}: </strong>
            {extra.total_purchased_extras} {t("PURCHASED")}, {extra.total_consumed_extras}{" "}
            {t("CONSUMED")}, {t("AMOUNT")}: {formatCurrency(extra.total_amount)}
          </Typography>
        ))
      ) : (
        <Typography sx={{ paddingLeft: "16px", paddingBottom: "8px", color: "#555" }}>
          {t("NO_SALES")}
        </Typography>
      )}
    </Box>
  );
};

export default EventStatisticsDetail;
