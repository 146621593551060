import { transformObjectWithUrls } from "../components/shared/FormsValidator";
import {
  addTimeToDate,
  sanitizeBoolean,
  sanitizeFloat,
  sanitizeForSending,
  sanitizeInt,
} from "../constants/utils";
import { EXTRA_TAGS } from "../constants/variables";
import { checkAvailableUrl } from "../services/eventsServices";

const nullExtraTags = [
  EXTRA_TAGS.BREAKFAST,
  EXTRA_TAGS.COMPLETE,
  EXTRA_TAGS.HALF_PENSION,
  EXTRA_TAGS.START_NIGHTS,
  EXTRA_TAGS.END_NIGHTS,
  EXTRA_TAGS.ADDITIONAL_EXTRA,
];

const prepareTicketsToSend = (data) => {
  const newTickets = data?.map((ticket, index) => {
    return {
      ...ticket,
      position: index,
      default_language: ticket.default_language || "ES",
      //
      description: sanitizeForSending(ticket.description),

      // NUMBERS
      initial_stock: sanitizeInt(ticket.initial_stock),
      price: sanitizeFloat(ticket.price),
      iva: sanitizeInt(ticket.iva),
      international_discount: sanitizeFloat(ticket.international_discount),
      early_payment_discount: sanitizeFloat(ticket.early_payment_discount),
      promotional_code_discount: sanitizeFloat(ticket.promotional_code_discount),

      //BOOLEANS
      only_international: sanitizeBoolean(ticket.only_international),
      only_in_app: sanitizeBoolean(ticket.only_in_app),
      // DATES
      early_payment_discount_date: ticket.early_payment_discount_date || null,
      start_date: addTimeToDate(ticket.start_date, ticket.start_date_time) || null,
      end_date: addTimeToDate(ticket.end_date, ticket.end_date_time) || null,
      second_payment_end_date:
        addTimeToDate(ticket.second_payment_end_date, ticket.second_payment_end_date_time) || null,

      //DISCOUNTS
      promotional_code_discounts:
        ticket.promotional_code_discounts?.map((discount) => {
          return {
            ...discount,
            discount: sanitizeFloat(discount.discount),
            is_active: sanitizeBoolean(discount.is_active),
            ticket_id: ticket.id || null,
            code: discount.code || ".",
          };
        }) || [],
      group_discounts:
        ticket.group_discounts?.map((discount) => {
          return {
            ...discount,
            min_size: sanitizeInt(discount.min_size),
            max_size: sanitizeInt(discount.max_size),
            discount: sanitizeFloat(discount.discount),
            ticket_id: ticket.id || null,
          };
        }) || [],
      // LANGUAGES
      ticket_language_infos: ticket.ticket_language_infos?.map((info) => {
        return {
          ...info,
          description: sanitizeForSending(info.description),
        };
      }),
    };
  });
  return newTickets;
};

const prepareExtrasToSend = (data) => {
  const newExtras = data?.map((extra, index) => {
    const nullStock = nullExtraTags.some((tag) => extra.tags?.includes(tag));
    return {
      ...extra,
      position: index,
      description: sanitizeForSending(extra.description),
      description_design: sanitizeForSending(extra.description_design),
      // NUMBERS
      initial_stock: nullStock ? null : sanitizeInt(extra.initial_stock) || 0,
      price: sanitizeFloat(extra.price) || 0,
      stock_by_ticket_max: sanitizeInt(extra.stock_by_ticket_max) || 0,
      times_can_be_consumed: sanitizeInt(extra.times_can_be_consumed),

      // BOOLEANS
      is_visible: sanitizeBoolean(extra.is_visible),
      only_international: sanitizeBoolean(extra.only_international),
      no_after_sales: sanitizeBoolean(extra.no_after_sales),

      name: extra.name || extra.tags || null,

      tickets_positions: extra.tickets_ids || [],

      // LANGUAGES
      extra_language_infos: extra.extra_language_infos?.map((info) => {
        return {
          ...info,
          description: sanitizeForSending(info.description),
          description_design: sanitizeForSending(info.description_design),
        };
      }),
    };
  });
  return newExtras;
};

const prepareBlockToSend = (data) => {
  const newBlocks = data?.map((block, index) => {
    return {
      ...block,
      position: index,
      description: sanitizeForSending(block.description),
      images_urls:
        block.images_urls === ""
          ? null
          : typeof block.images_urls === "string"
          ? [block.images_urls]
          : block.images_urls,
    };
  });
  return newBlocks;
};

const prepareItineraryToSend = (data) => {
  const newItineraries = data?.map((itinerary, index) => {
    return {
      ...itinerary,
      position: index,
      description: sanitizeForSending(itinerary.description),
      nights: sanitizeInt(itinerary.nights),
      start_point: sanitizeBoolean(itinerary.start_point),
      end_point: sanitizeBoolean(itinerary.end_point),
      // LANGUAGES
      itinerary_language_infos: itinerary.itinerary_language_infos?.map((info) => {
        return {
          ...info,
          description: sanitizeForSending(info.description),
          itinerary_id: info?.itinerary_id || itinerary?.id || null,
        };
      }),
    };
  });
  return newItineraries;
};

// BOOLEANS
const booleanFields = [
  "has_double_payment",
  "passport_required",
  "without_night",
  "multi_date",
  "autovalidation",
  "is_international",
  "is_visible",
];

//NUMBERS
const integerFields = ["min_age", "limit_tickets_order", "countries", "cities", "days", "nights"];
const floatFields = ["rating", "first_payment_percentage", "latitude", "longitude"];

//OPTIONAL DATES
const dateFields = [
  "start_date",
  "start_date_time",
  "end_date",
  "end_date_time",
  "publication_date",
  "publication_date_time",
  "end_of_publication_date",
  "end_of_publication_date_time",
  "start_sale_date",
  "start_sale_date_time",
  "end_sale_date",
  "end_sale_date_time",
  "second_payment_end_date",
  "second_payment_end_date_time",
  "second_payment_start_date",
  "second_payment_start_date_time",
];

export const prepareTripToSend = async (data) => {
  const withImages = await transformObjectWithUrls(data);
  const newData = JSON.parse(JSON.stringify(withImages));

  //Check url
  const responseUrl = await checkAvailableUrl(newData.seo_name);
  if (!responseUrl && !data.id) {
    const error = new Error("URL not available");
    error.response = {
      data: {
        error: "URL not available",
      },
    };
    throw error;
  }

  // OBJECTS
  newData.description = sanitizeForSending(newData.description);
  newData.not_included_options = sanitizeForSending(newData.not_included_options);
  newData.additional_info = sanitizeForSending(newData.additional_info);

  const optionValues = [];
  for (let i = 0; i < newData?.included_options?.length; i++) {
    optionValues.push(newData.included_options[i]?.value);
  }
  newData.included_options = JSON.stringify(optionValues);

  //NUMBERS
  integerFields.forEach((field) => {
    newData[field] = sanitizeInt(newData[field]);
  });

  floatFields.forEach((field) => {
    newData[field] = sanitizeFloat(newData[field]);
  });

  //OPTIONAL DATES
  dateFields.forEach((field) => {
    newData[field] = newData[field] || null;
  });

  // BOOLEANS
  booleanFields.forEach((field) => {
    newData[field] = sanitizeBoolean(newData[field]);
  });

  if (
    typeof newData.slider_urls === "string" ||
    (typeof newData.slider_urls === "object" && newData.slider_urls.mime)
  ) {
    newData.slider_urls = [newData.slider_urls];
  }

  // CATEGORIES
  if (newData.event_categories && newData.event_categories.length >= 0 && newData.id) {
    newData.event_categories.forEach((element) => {
      element.event_id = newData.id;
    });
  }

  newData.tickets = prepareTicketsToSend(newData.tickets);
  newData.extras = prepareExtrasToSend(newData.extras);
  newData.blocks = prepareBlockToSend(newData.blocks);
  newData.itineraries = prepareItineraryToSend(newData.itineraries);

  // LANGUAGES
  newData.event_language_infos = newData.event_language_infos?.map((info) => {
    return {
      ...info,
      description: sanitizeForSending(info.description),
      additional_info: sanitizeForSending(info.additional_info),
      not_included_options: sanitizeForSending(info.not_included_options),
      event_id: info.event_id || null,
    };
  });

  newData.total_capacity = 0;
  for (let i = 0; i < newData?.tickets?.length; i++) {
    newData.total_capacity += parseInt(newData.tickets[i].initial_stock);
  }

  return newData;
};

export function prepareTripToReceive(data = {}) {
  const { event_type, extras, id, tickets, details = {}, moreInformation = {} } = data;

  const trip = {
    event_type,
    extras,
    id,
    tickets,
    ...details,
    ...moreInformation,
  };

  // BOOLEANS
  booleanFields.forEach((field) => {
    trip[field] = sanitizeBoolean(trip[field]);
  });

  // ITINERARIES
  trip.itineraries = trip?.itineraries?.map((itinerary) => {
    return {
      ...itinerary,
      start_point: sanitizeBoolean(itinerary.start_point),
      end_point: sanitizeBoolean(itinerary.end_point),
    };
  });

  //TICKETS
  trip.tickets = trip?.tickets?.map((ticket) => {
    return {
      ...ticket,
      is_visible: sanitizeBoolean(ticket.is_visible),
      only_in_app: sanitizeBoolean(ticket.only_in_app),
      only_international: sanitizeBoolean(ticket.only_international),
    };
  });

  // EXTRAS
  trip.extras = trip?.extras
    ?.sort((a, b) => a.position - b.position)
    ?.map((extra) => {
      return {
        ...extra,
        is_visible: sanitizeBoolean(extra.is_visible),
        only_international: sanitizeBoolean(extra.only_international),
        no_after_sales: sanitizeBoolean(extra.no_after_sales),
      };
    });

  //TOUR LEADERS
  trip.tour_leader_ids = trip?.tour_leader_ids?.map((tourLeaderId) => tourLeaderId?.tour_leader_id);

  return trip;
}
