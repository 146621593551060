import { CheckCircleOutlineOutlined, DoDisturb, RemoveRedEyeOutlined } from "@mui/icons-material";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate, sanitizeBoolean } from "../../constants/utils";
import CustomDialog from "../shared/CustomDialog";
import OrderButtons from "../shared/OrderButtons";

export const MailingTable = ({
  data,
  handleOpenDetails,
  handleChangeIsActivePlan,
  order,
  handleOrder,
}) => {
  const { t } = useTranslation();
  const [openPlan, setOpenPlan] = useState({
    open: false,
    plan: null,
  });

  const handleEdit = (e, id) => {
    e.stopPropagation();
    handleOpenDetails(id);
  };

  const handleOpenPlan = (plan) => {
    setOpenPlan({ open: true, plan: plan });
  };

  const handleConfirmChangePlan = () => {
    handleChangeIsActivePlan(openPlan.plan);
    setOpenPlan({ open: false, plan: null });
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ borderRadius: "20px", mb: 2 }}>
        <Table sx={{ whiteSpace: "nowrap" }}>
          <TableHead>
            <TableRow sx={{ borderBottom: "2px solid var(--secondary-color)" }}>
              <TableCell>{t("DETAILS")}</TableCell>
              <TableCell>{t("MESSAGE_SUBJECT")}</TableCell>
              <TableCell>
                <OrderButtons
                  columnName={t("PRODUCT")}
                  id="name"
                  order={order}
                  handleClickAsc={handleOrder}
                  handleClickDesc={handleOrder}
                />
              </TableCell>
              <TableCell>
                <OrderButtons
                  columnName={t("SENT")}
                  id="been_sent"
                  order={order}
                  handleClickAsc={handleOrder}
                  handleClickDesc={handleOrder}
                />
              </TableCell>
              <TableCell>{t("STATUS")}</TableCell>
              <TableCell>
                <OrderButtons
                  columnName={t("CREATED")}
                  id="created_at"
                  order={order}
                  handleClickAsc={handleOrder}
                  handleClickDesc={handleOrder}
                />
              </TableCell>
              <TableCell>{t("ACTIONS")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  backgroundColor: !sanitizeBoolean(row?.is_active) ? "var(--grey-cancelled)" : "",
                }}
              >
                <TableCell>
                  {row?.trigger_type ? t(row?.trigger_type?.toUpperCase()) : t("DIRECT")}
                </TableCell>
                <TableCell>{row.subject}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  {row?.sent && row.sent.length > 0 ? (
                    row.sent.map((s, si) => (
                      <p key={`sent-a-${si}`}>{s.sent_at ? formatDate(s.sent_at) : ""}</p>
                    ))
                  ) : row?.date_to_send ? (
                    <div style={{ color: "var(--oniria-red)", textAlign: "center" }}>
                      {t("PROGRAMMED_FOR")}
                      <br />
                      {formatDate(row.date_to_send)}
                    </div>
                  ) : row.dates_to_send && row.dates_to_send.length > 0 ? (
                    <div style={{ color: "var(--oniria-red)", textAlign: "center" }}>
                      {t("PROGRAMMED_FOR")}
                      <br />
                      {row.dates_to_send.map((date, dateI) => (
                        <p key={`date-${dateI}`}>
                          {date.date_to_send
                            ? `${formatDate(date.date_to_send)} (${date.name})`
                            : ""}
                        </p>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {!sanitizeBoolean(row?.is_active) ? (
                    <Typography sx={{ color: "var(--oniria-red)" }}>{t("CANCELLED")}</Typography>
                  ) : row?.sent && row.sent.length > 0 ? (
                    row.sent.map((s, sI) => (
                      <Typography
                        key={`sent-${sI}`}
                        sx={{ color: s.sent_at ? "var(--green-success)" : "var(--oniria-red)" }}
                      >
                        {s.sent_at ? t("SENT") : t("PENDING")}
                      </Typography>
                    ))
                  ) : (
                    <Typography sx={{ color: "var(--oniria-red)" }}>{t("PENDING")}</Typography>
                  )}
                </TableCell>
                <TableCell>{formatDate(row.created_at)}</TableCell>
                <TableCell>
                  <Tooltip title={t("SEE_DETAILS")}>
                    <IconButton onClick={(e) => handleEdit(e, row.id)}>
                      <RemoveRedEyeOutlined sx={{ color: "var(--oniria-gold)" }} />
                    </IconButton>
                  </Tooltip>
                  {row?.trigger_type && (
                    <Tooltip
                      title={
                        sanitizeBoolean(row?.is_active) ? t("DEACTIVATE_PLAN") : t("ACTIVATE_PLAN")
                      }
                    >
                      <IconButton onClick={() => handleOpenPlan(row)}>
                        {sanitizeBoolean(row?.is_active) ? (
                          <DoDisturb sx={{ color: "var(--oniria-gold)" }} />
                        ) : (
                          <CheckCircleOutlineOutlined sx={{ color: "var(--oniria-gold)" }} />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomDialog
        isOpen={openPlan.open}
        onClose={() => setOpenPlan({ open: false, id: "" })}
        title={
          sanitizeBoolean(openPlan?.plan?.is_active) ? t("DEACTIVATE_PLAN") : t("ACTIVATE_PLAN")
        }
        content={t("CONFIRM_CHANGE_PLAN")}
        onAccept={handleConfirmChangePlan}
      />
    </>
  );
};
