import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../constants/utils";

const RegisterAssistantDialog = ({
  openCheck,
  handleCloseCheck,
  validateAssistant,
  selectedOption,
  handleCheckboxChange,
  handleRegisterButtonClick,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={openCheck}
      onClose={handleCloseCheck}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className="oniria-colorText" sx={{ fontWeight: "bolder" }}>
        {t("REGISTER_ATTENDANT")}
      </DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  value="ticket"
                  disabled={validateAssistant.consumed_at !== null}
                  checked={
                    validateAssistant.consumed_at !== null || selectedOption.includes("ticket")
                  }
                  onChange={handleCheckboxChange}
                  size="large"
                  className="checkbox-oniria"
                />
              }
              label={`${validateAssistant?.ticket_name}
                ${validateAssistant?.start_date ? formatDate(validateAssistant?.start_date) : ""} 
                ${validateAssistant?.end_date ? `- ${formatDate(validateAssistant.end_date)}` : ""}
                `}
            />
            {validateAssistant.extras &&
              validateAssistant.extras.map((extra, index) => (
                <>
                  <FormControlLabel
                    key={`extra${index}`}
                    control={
                      <Checkbox
                        sx={{ ml: 2 }}
                        key={extra.extra_id}
                        disabled={
                          extra.extra_consumed_at !== null ||
                          (extra.extra_consumitions && extra.extra_consumitions.length > 0)
                        }
                        checked={
                          extra.extra_consumed_at !== null ||
                          selectedOption.includes(`extra${index}`)
                        }
                        value={`extra${index}`}
                        onChange={(e) => handleCheckboxChange(e, extra)}
                        size="large"
                        className="checkbox-oniria"
                      />
                    }
                    label={extra.extra_name}
                  />
                  <Grid sx={{ ml: 4 }} container flexDirection="column">
                    {extra.extra_consumitions && extra.times_can_be_consumed > 1
                      ? [...Array(extra.times_can_be_consumed)].map((_, i) => (
                          <FormControlLabel
                            key={`extra-item-${i}`}
                            control={
                              <Checkbox
                                sx={{ ml: 2 }}
                                key={index}
                                disabled={extra.extra_consumitions[i]?.consumed_at != null}
                                checked={
                                  extra.extra_consumitions[i]?.consumed_at != null ||
                                  selectedOption.includes(`extra${index}-item${i}`)
                                }
                                value={`extra${index}-item${i}`}
                                onChange={(e) => handleCheckboxChange(e, extra)}
                                size="large"
                                className="checkbox-oniria"
                              />
                            }
                            label={extra.extra_name}
                          />
                        ))
                      : null}
                  </Grid>
                </>
              ))}
            <FormControlLabel
              control={
                <Checkbox
                  size="large"
                  className="checkbox-oniria"
                  value="all"
                  checked={selectedOption.includes("all")}
                  onChange={handleCheckboxChange}
                />
              }
              label="Validar todo"
            />
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}
        >
          <Button
            className="oniria-btn-cancel"
            variant="contained"
            size="medium"
            sx={{ fontSize: "12px", borderRadius: 3, mr: 2 }}
            onClick={handleCloseCheck}
          >
            {t("CANCEL")}
          </Button>
          <Button
            type="submit"
            className="oniria-btn"
            variant="contained"
            size="medium"
            sx={{ fontSize: "12px", borderRadius: 3 }}
            onClick={(e) => handleRegisterButtonClick(e, selectedOption)}
          >
            {t("ACCEPT")}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default RegisterAssistantDialog;
