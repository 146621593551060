import { Grid, Pagination, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import { MailingFilters } from "../components/mailing/MailingFilters";
import { MailingTable } from "../components/mailing/MailingTable";
import { MailingPlanDetails } from "../components/mailing/MaillingPlanDetails";
import { EventNotificationDialog } from "../components/mailing/notificationDialog/NotificationDialog";
import { Loading } from "../components/shared/Loading";
import SelectPerPage from "../components/shared/SelectPerPage";
import { toastMessageError, toastMessageSuccess } from "../components/shared/toastMessage";
import { ROUTES } from "../constants/routes";
import { PAGINATION_STYLES } from "../constants/styles";
import { sanitizeBoolean } from "../constants/utils";
import { useQueryMailing } from "../hooks/queries/mailing/useQueryMailing";
import useDebounce from "../hooks/useDebouncing";
import usePagination from "../hooks/usePagination";
import { changeActiveMailingPlan } from "../services/mailingServices";

export const MailingPage = () => {
  const { t } = useTranslation();
  const breadcrumbs = [{ name: t("NOTIFICATIONS_MANAGER"), link: ROUTES.MAILING }];
  const { page, perPage, changePage, changePerPage } = usePagination();

  const [openMailDialog, setOpenMailDialog] = useState({
    open: false,
    type: "", // direct, programmed
  });
  const [filters, setFilters] = useState({
    search: "",
    type: "all",
    eventId: "",
    status: "all",
    order: { asc: [], desc: ["created_at"] },
  });
  const [seeDetails, setSeeDetails] = useState({
    open: false,
    planId: "",
  });

  const {
    data: { data: mailings = [], amount: totalMailing = 0 } = {},
    isLoading,
    isRefetching,
    refetch,
  } = useQueryMailing(filters, page, perPage);

  const updateFilter = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    changePage(null, 1);
  };

  const handleSearchChange = (event) => {
    updateFilter("search", event.target.value);
  };
  const debounceSearch = useDebounce(handleSearchChange, 300);

  const numberPages = Math.ceil(parseFloat(totalMailing || "1") / parseFloat(perPage));

  const handleOpenDetails = (planId) => {
    setSeeDetails({ open: true, planId });
  };

  const handleCloseDetails = () => {
    setSeeDetails({ open: false, planId: "" });
  };

  const handleChangeIsActivePlan = async (plan) => {
    const { id, is_active } = plan;
    try {
      await changeActiveMailingPlan(id, !sanitizeBoolean(is_active));
      toastMessageSuccess(t("PLAN_UPDATED_SUCCESSFULLY"));
      refetch();
    } catch (error) {
      const errorMessage = error?.response?.data?.error ?? t("ERROR_UPDATING_PLAN");
      toastMessageError(errorMessage);
    }
  };

  const handleOrder = (id, type) => {
    const orderCopy = { ...filters.order };
    const oppositeType = type === "asc" ? "desc" : "asc";
    if (orderCopy[type]?.includes(id)) {
      orderCopy[type] = orderCopy[type]?.filter((item) => item !== id);
    } else {
      orderCopy[type].push(id);
      orderCopy[oppositeType] = orderCopy[oppositeType]?.filter((item) => item !== id);
    }
    setFilters((prev) => ({
      ...prev,
      order: orderCopy,
    }));
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} />
      {seeDetails.open ? (
        <MailingPlanDetails planId={seeDetails.planId} handleClose={handleCloseDetails} />
      ) : (
        <>
          <Grid
            item
            xs={11}
            sm={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "initial",
            }}
          >
            <MailingFilters
              onSearchChange={debounceSearch}
              filters={filters}
              updateFilter={updateFilter}
              setOpenMailDialog={setOpenMailDialog}
            />
          </Grid>
          {totalMailing > 0 && (
            <Grid
              item
              xs={11}
              sm={12}
              container
              justifyContent={{ xs: "center", sm: "space-between" }}
              alignItems={"center"}
              pb={2}
              gap={{ xs: 2, sm: 0 }}
              mt={3}
            >
              <Typography>
                {t("TOTAL_MAILING")}: {totalMailing}
              </Typography>
              {numberPages >= 1 && (
                <Grid item xs={12} md={8} container justifyContent="flex-end">
                  <Grid item>
                    <SelectPerPage text={t("MAILING")} change={changePerPage} value={perPage} />
                  </Grid>
                  <Pagination
                    count={numberPages}
                    mr={3}
                    sx={PAGINATION_STYLES}
                    page={page}
                    onChange={changePage}
                  />
                </Grid>
              )}
            </Grid>
          )}
          {isRefetching || isLoading ? (
            <Loading />
          ) : mailings?.length === 0 ? (
            <Grid ml={3} mt={3}>
              {t("NO_DATA_AVAILABLE")}
            </Grid>
          ) : (
            <MailingTable
              data={mailings}
              handleOpenDetails={handleOpenDetails}
              handleChangeIsActivePlan={handleChangeIsActivePlan}
              order={filters.order}
              handleOrder={handleOrder}
            />
          )}
          {numberPages >= 1 && (
            <Grid mb={2}>
              <Pagination
                count={numberPages}
                sx={PAGINATION_STYLES}
                page={page}
                onChange={changePage}
              />
            </Grid>
          )}
        </>
      )}
      <EventNotificationDialog
        open={openMailDialog.open}
        onClose={() => setOpenMailDialog({ open: false })}
        mailType={openMailDialog.type}
        filters={filters}
      />
    </>
  );
};
