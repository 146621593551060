import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import useEventLanguage from "../../../../hooks/useEventLanguage";
import { checkField } from "../../../shared/FormsValidator";
import { focusColor } from "../../../shared/textFieldStyle";
import LanguageTabs from "../../languages/LanguageTabs";

const EventShortDescription = ({
  trip,
  formParams,
  setFormErrors,
  detailsErrors,
  isDisabled,
  buttonClicked,
  setFormData,
  setEventLanguageInfoError,
}) => {
  const { t } = useTranslation();
  const { event_language_infos, default_language } = trip;

  const {
    selectedLanguage,
    setSelectedLanguage,
    selectedLanguageInfo,
    selectedLanguageIndex,
    handleChangeAttribute,
  } = useEventLanguage(
    event_language_infos,
    setFormData,
    setEventLanguageInfoError,
    default_language
  );

  const languageErrors = detailsErrors?.event_language_infos[selectedLanguageIndex];

  return (
    <>
      {event_language_infos?.length > 0 ? (
        <LanguageTabs
          event_languages_info={trip.event_language_infos}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          defaultLanguage={default_language}
        />
      ) : null}
      {selectedLanguage === default_language ? (
        <TextField
          onChange={(e) =>
            checkField(
              "details",
              "short_description",
              e,
              /^.{0,80}$/,
              t("MAX_80"),
              true,
              formParams,
              setFormErrors
            )
          }
          value={trip.short_description || ""}
          margin="normal"
          multiline
          fullWidth
          id="short_description"
          name="short_description"
          autoComplete="short_description"
          placeholder={t("SHORT_DESCRIPTION")}
          helperText={
            detailsErrors.short_description !== "empty" ? detailsErrors.short_description : ""
          }
          error={
            (detailsErrors.short_description !== "" &&
              detailsErrors.short_description !== "empty") ||
            (detailsErrors.short_description === "empty" && buttonClicked)
          }
          disabled={isDisabled}
          sx={focusColor}
        />
      ) : (
        <TextField
          onChange={(e) =>
            handleChangeAttribute("short_description", e.target.value, /^.{0,80}$/, t("MAX_80"))
          }
          value={selectedLanguageInfo?.short_description || ""}
          margin="normal"
          fullWidth
          id="short_description"
          name="short_description"
          label={t("SHORT_DESCRIPTION")}
          autoComplete="short_description"
          error={
            languageErrors?.short_description !== "" &&
            (languageErrors?.short_description === "empty" || buttonClicked)
          }
          helperText={
            languageErrors?.short_description && languageErrors?.short_description !== "empty"
              ? languageErrors?.short_description
              : ""
          }
          disabled={isDisabled}
          sx={focusColor}
        />
      )}
    </>
  );
};

export default EventShortDescription;
