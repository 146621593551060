import { Button, Grid, Pagination, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import OrderDetail from "../../components/events/OrderDetail";
import Header from "../../components/Header";
import OrdersFilters from "../../components/orders/OrdersFilters";
import OrdersTable from "../../components/orders/OrdersTable";
import { Loading } from "../../components/shared/Loading";
import SelectPerPage from "../../components/shared/SelectPerPage";
import {
  CSV_ORDERS_HEADERS,
  getOneMonthAgo,
  getOrderAmount,
  getOrderCommission,
  getOrderPremiseCommission,
  getOrderTotalAmount,
  isOrderFailed,
  isOrderTotalPayed,
  PURCHASE_TYPE,
} from "../../constants/orders";
import { ROUTES } from "../../constants/routes";
import { PAGINATION_STYLES } from "../../constants/styles";
import { formatCurrency, formatDate, formatHours, getPremiseName } from "../../constants/utils";
import { useQueryOrdersList } from "../../hooks/queries/useQueryOrdersList";
import { useQueryPremisesList } from "../../hooks/queries/useQueryPremisesList";
import usePagination from "../../hooks/usePagination";
import useUserRoles from "../../hooks/useUserRoles";

const Orders = ({ fromCards }) => {
  const { t } = useTranslation();
  const breadcrumbs = [
    {
      name: t("ORDERS"),
      link: ROUTES.ORDERS,
    },
  ];

  const { isSuperAdmin, isAdmin, isManager } = useUserRoles();

  const canSeeTotalPrice = isSuperAdmin || isAdmin || isManager;

  const [showDetailOrder, setShowDetailOrder] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const { page, perPage, changePage, changePerPage } = usePagination();
  const [filters, setFilters] = useState({
    eventId: "",
    premiseId: "",
    purchaseType: fromCards ? PURCHASE_TYPE.CARD : "",
    orderStatus: "",
    fromDate: getOneMonthAgo(),
    toDate: "",
    paymentMethod: "",
    search: "",
    asc: "",
    desc: "created_at",
  });
  const { data, isLoading, refetch } = useQueryOrdersList(filters, page, perPage);
  const { data: premises } = useQueryPremisesList();

  const [selectedOrders, setSelectedOrders] = useState(data?.data || []);
  const [selectedOrdersIds, setSelectedOrdersIds] = useState([]);

  const totalPages = Math.ceil(Number(data?.amount ?? 0) / Number(data?.perPage ?? 10));

  const handleOpenDetail = (e, order) => {
    e.preventDefault();
    setSelectedOrder(order);
    setShowDetailOrder(true);
  };

  const ordersCsvFormat = (orders) => {
    return orders
      .filter((order) => {
        if (selectedOrdersIds.length > 0) {
          return selectedOrdersIds.includes(order.id);
        }
        return true;
      })
      .map((order) => [
        order.event_name || order.card_name,
        getPremiseName(order.event_premise_id || order.card_premise_id, premises),
        order.bought_in_domain,
        formatCurrency(getOrderAmount(order)),
        formatCurrency(getOrderCommission(order)),
        formatCurrency(getOrderPremiseCommission(order)),
        formatCurrency(getOrderTotalAmount(order)),
        order.payed_at
          ? `${formatDate(order.payed_at)} ${formatHours(order.payed_at)}`
          : order.first_payment_payed_at
          ? `${formatDate(order.first_payment_payed_at)} ${formatHours(
              order.first_payment_payed_at
            )}`
          : "",
        order.email,
        `${
          order.payment_type
            ? t(order?.payment_type?.toUpperCase())
            : t(order?.first_payment_type?.toUpperCase())
        }
        ${
          !isOrderTotalPayed(order) && !isOrderFailed(order)
            ? ` (${t("PENDING_SECOND_PAYMENT")})`
            : ""
        }`,
      ]);
  };

  const handleSelectOrders = (e) => {
    if (e.target.checked) {
      setSelectedOrdersIds([...selectedOrdersIds, e.target.value]);
    } else {
      setSelectedOrdersIds(selectedOrdersIds.filter((id) => id !== e.target.value));
    }
  };

  useEffect(() => {
    setSelectedOrders(data?.data || []);
  }, [data]);

  useEffect(() => {
    if (!showDetailOrder) {
      refetch();
    }
  }, [showDetailOrder]);

  return (
    <>
      {!fromCards && <Header breadcrumbs={breadcrumbs} description={t("HEADER_TEXT_ORDERS")} />}
      {showDetailOrder ? (
        <OrderDetail
          order={selectedOrder.id}
          transactionId={selectedOrder.transaction_id}
          firstPaymentTransactionId={selectedOrder.first_payment_transaction_id}
          onBack={() => setShowDetailOrder(false)}
        />
      ) : (
        <>
          <OrdersFilters filters={filters} setFilters={setFilters} fromCards={fromCards} />
          <Grid container justifyContent="flex-end" marginTop={2}>
            {(isSuperAdmin || isAdmin || isManager) && (
              <Button
                disableElevation
                variant="contained"
                className="oniria-btn"
                aria-label="download csv"
              >
                <CSVLink
                  data={ordersCsvFormat(selectedOrders)}
                  filename={"pedidos.csv"}
                  target="_blank"
                  separator={";"}
                  headers={CSV_ORDERS_HEADERS}
                  className="csv-link"
                  style={{ color: "#fff" }}
                >
                  {t("DOWNLOAD_CSV")}
                </CSVLink>
              </Button>
            )}
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            marginBottom={2}
            marginTop={2}
          >
            <Grid item xs={4}>
              <Typography variant="h7">
                {t("TOTAL_ORDERS")} : {data?.amount || 0}
              </Typography>
            </Grid>
            {totalPages >= 1 ? (
              <Grid item xs={8} container justifyContent="flex-end">
                <Grid item>
                  <SelectPerPage
                    text={t("ORDERS")}
                    change={changePerPage}
                    value={perPage}
                    total={data?.amount}
                  />
                </Grid>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={changePage}
                  sx={PAGINATION_STYLES}
                />
              </Grid>
            ) : null}
          </Grid>
          {isLoading ? (
            <Loading />
          ) : data ? (
            <>
              <OrdersTable
                data={data.data}
                handleOpenDetail={handleOpenDetail}
                premises={premises}
                handleSelectOrders={handleSelectOrders}
                selectedOrdersIds={selectedOrdersIds}
                fromCards={fromCards}
                canSeeTotalPrice={canSeeTotalPrice}
              />
              {totalPages > 1 ? (
                <Grid container mb={3} justifyContent="center">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={changePage}
                    sx={PAGINATION_STYLES}
                  />
                </Grid>
              ) : null}
            </>
          ) : (
            <Typography>{t("NO_DATA")}</Typography>
          )}
        </>
      )}
    </>
  );
};
export default Orders;
