import { CloudDownload } from "@mui/icons-material";
import { Button, ButtonGroup, Grid, Popover } from "@mui/material";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { useDownloadAssistants } from "../../../hooks/useDownloadAssistants";

export const DownloadAssistantsButton = ({ eventId, selectedDomain, filters }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [enableDownload, setEnableDownload] = useState(false);

  const { generatePDF, tableHeadersCsv, tableDataCsv, isSuccess } = useDownloadAssistants(
    eventId,
    selectedDomain,
    filters,
    enableDownload
  );

  const handleOpenPopover = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setEnableDownload(true);
  };

  const handleClosePopover = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;

  return (
    <Grid item xs={12}>
      <Button
        className="oniria-btn"
        variant="contained"
        size="small"
        sx={{
          fontSize: "12px",
          borderRadius: 3,
          display: "flex",
          alignItems: "center",
          gap: "6px",
          width: "100%",
        }}
        onClick={handleOpenPopover}
      >
        <CloudDownload />
        {t("DOWNLOAD_LIST")}
      </Button>
      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        id={popoverId}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ButtonGroup variant="contained" orientation="vertical" aria-label="vertical button group">
          <Button
            disableElevation
            fullWidth
            variant="contained"
            className="popover-btn"
            aria-label="download csv"
            disabled={!isSuccess}
          >
            <CSVLink
              data={tableDataCsv}
              filename={"assistants.csv"}
              target="_blank"
              separator={";"}
              headers={tableHeadersCsv}
              className="csv-link"
              style={{ textAlign: "center" }}
            >
              {t("CSV_FORMAT")}
              <p style={{ fontSize: "10px", textTransform: "lowercase" }}>({t("INFO_FULL")})</p>
            </CSVLink>
          </Button>
          <Button
            disableElevation
            fullWidth
            variant="contained"
            className="popover-btn"
            aria-label="download pdf"
            onClick={generatePDF}
            style={{ textAlign: "center", display: "flex", flexDirection: "column" }}
            disabled={!isSuccess}
          >
            {t("PDF_FORMAT")}
            <p style={{ fontSize: "10px", textTransform: "lowercase" }}>({t("INFO_BASE")})</p>
          </Button>
        </ButtonGroup>
      </Popover>
    </Grid>
  );
};
